.breadcrumb{
    background: transparent;
    position: absolute;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    left: 0;
	right: 0;
	top: 22px;
	ul{
		display: flex;
		align-items: center;
		li{
			font-size: 14px;
			color:$blue;
			padding-right: 30px;
			position: relative;
			&:after{
				content: "\e925";
				font-family: 'icomoon';
				display: block;
				position: absolute;
				right: 15px;
				top: 2px;
				color: white;
				transform: rotate(0deg);
				font-size: 9px;
			}
			a{
				color:white;
				opacity: 0.6;
			}
			span{
				color:white;
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
		}
	}
}