footer{
    padding-top: 120px;
    position: relative;
    overflow: hidden;
    background-color: #212121;
    &:before{
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        top: 10px;
        width: 299px;
        height: 426px;
        left: -244px;
    }
    &:after{
        content: "";
        background-repeat: no-repeat;
        position: absolute;
        bottom: 10px;
        width: 255px;
        height: 286px;
        right: -115px;
    }
    .footer-top{
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        @media #{$mobile} { 
            flex-flow: wrap;
        }
        .newsletter{
            width: 38%;
            @media #{$mobile} { 
                width: 100%;
                margin-bottom: 30px;
            }   
            h3{
                font-size: 28px;
                font-weight: 300;
            }
            p{
                font-size: 17px;
                font-weight: 300;
            }
            form{
                .form-group{
                    position: relative;
                    display: flex;
                    align-items: center;
                    input[type="submit"]{
                        position: absolute;
                        right: 5px;
                    }
                    input[type="email"]{
                        border-radius: 40px;
                        border-color:#878787;
                        height: 44px;
                        padding-left: 24px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .suiveznous{
            h3{
                margin-bottom: 20px;
                color:white;
                font-size:26px;
                text-align: center;
            }
        }
    }
    .footer-bottom{
        display: flex;
        justify-content: space-between;
        @media #{$mobile} { 
            flex-flow: wrap;
        }   
        h3{
            font-size: 24px;
            font-weight: 300;
            color:black;
            margin-bottom: 25px;
            @media #{$mobile} { 
                font-size: 18px;
            }
        }
        .bloc{
            @media #{$mobile} { 
                width: 50%;
                margin-bottom: 20px;
            }
        }
        ul{
            li{
                margin-bottom: 5px;
                a{
                    color:black;
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }
    }
}
.social{
    ul{
        display: flex;
        li{
            &:first-child{
                a{
                    margin-left: 0;
                }
            }
            a{
                font-size: 0;
                margin: 0 9px;
                border: 1px solid #b0b0b0;
                padding: 7px;
                width: 55px;
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                &:hover{
                    background-color: $orange;
                    &:before{
                        color:#b0b0b0;
                    }
                }
                &:before{
                    font-size: 19px;
                    font-family: $icomoon;
                    color:#b0b0b0;
                }
                &.instagram{
                    &:before{
                        content: "\e923";
                    }
                }
                &.linkden{
                    &:before{
                        content: "\e924";
                    }
                }
                &.twitter{
                    &:before{
                        content: "\e922";
                    }
                }
                &.facebook{
                    &:before{
                        content: "\e921";
                    }
                }
                &.youtube{
                    &:before{
                        content: "\e943";
                    }
                }
            }
        }
    }
}
.copyright{
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    padding: 0 140px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 12px;
    color:$blue;
    font-weight: 300;
    @media #{$mobile} { 
        padding:0 20px;
    }
    .top{
        width: 100%;
        padding: 22px 0;
        border-bottom: 1px solid #b0b0b0;
        border-top: 1px solid #b0b0b0;
        margin-bottom: 9px;
    }
    .bottom{
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 16px 0;
        color: #5c5c5c;
        text-transform: uppercase;
        font-size: 14px;
    }
    .scrolltotop{
        cursor: pointer;
        &:hover{
            &:before{
                color:$orange;
            }
        }
        &:before{
            content: "\e908";
            font-family: $icomoon;
            color:$blue;
            display: block;
            font-size: 11px;
            transform: rotate(-90deg);
            font-size: 17px;
        }
    }
    ul{
        display: flex;
        justify-content: center;
        @media #{$mobile} { 
            flex-flow: wrap;
        }   
        li{
            position: relative;
            padding: 0 15px;
            @media #{$mobile} { 
                width:100%;
                margin-bottom: 8px;
                text-align: center;
            }   
            &:last-child{
                &:before{
                    display: none;
                }
            }
            &:before{
                content: "";
                width: 4px;
                height: 4px;
                position: absolute;
                right: 0;
                top: 4px;
                border-radius: 50px;
                background-color: $orange;
                @media #{$mobile} { 
                    display: none;
                }
            }
            a{
                font-size: 14px;
                color:#b0b0b0;
                text-transform: uppercase;
            }
            &:hover{
                a{
                    color:$orange;
                }
            }
        }
    }
}
.cookie{
    background-color: $black;
    color:white;
    padding: 30px 15px;
    font-size: 14px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999999;
    .close{
        position: absolute;
        right: -23px;
        cursor: pointer;
        width: 24px;
        top: -16px;
        &:before{
            content: "";
            width: 1px;
            height: 25px;
            background-color: white;
            display: block;
            position: absolute;
            left: 11px;
            top: 0px;
            transform: rotate(-45deg);
        }
        &:after{
            content: "";
            width: 1px;
            height: 25px;
            background-color: white;
            display: block;
            position: absolute;
            left: 11px;
            top: 0;
            transform: rotate(45deg);            
        }
    }
    .content{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.btn-c{
    background-color:transparent;
    border:0;
    font-size:16px;
    padding: 8px 15px;
    margin-right: 4px;
    height: 35px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
    &:hover{
        background-color: $orange !important;
        color:white !important;
    }
    &.bleu{
        background-color: $blue;
        color:white;
    }
    &.white{
        background-color: white;
        color:$blue;
    }
}