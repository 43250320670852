.button {
  &--niveau1{
	padding: rem(10) rem(35);
	border:1px solid transparent;
	transition: 0.3s all;  	
	background-color: $blue;
	color: white;
	font-size: rem(21);
	cursor: pointer;
	display: inline-block;
	position: relative;
	z-index: 1;
	border-radius: 50px;
	overflow: hidden;
	 @media #{$mobile} {
		font-size: rem(15);
	 }
	&:after{
		content: "";
		position: absolute;
		z-index: -1;
	    width: 0%;
	    height: 100%;
	    bottom: 0;
	    left: 0;
	    background: transparent;	
	    transition: all 0.3s;	
	}	 
    &:hover{
	  color:$blue;
	  border-color:$blue;
	  background-color:transparent;
      &:after{
		  width:100%;
		  background-color: transparent;
      }
      a{
      	color:$blue;
      }
    }    
  }
}
button{
	ouline:none;
}
.button-primary{
	padding: rem(10) rem(35);
	border:1px solid transparent;
	transition: 0.3s all;  	
	background-color: $blue;
	color: white;
	font-size: rem(21);
	cursor: pointer;
	display: inline-block;
	position: relative;
	z-index: 1;
	border-radius: 50px;
	overflow: hidden;
	 @media #{$mobile} {
		font-size: rem(15);
	 }
	&:after{
		content: "";
		position: absolute;
		z-index: -1;
	    width: 0%;
	    height: 100%;
	    bottom: 0;
	    left: 0;
	    background: $orange;	
	    transition: all 0.3s;	
	}	 
    &:hover{
	  color:$blue;
	  border-color:$blue;
	  background-color:transparent;
      &:after{
		  width:100%;
		  background-color: transparent;
      }
      a{
      	color:$orange;
      }
    } 	
}
.button-default{
	padding: rem(10) rem(35);
	border:1px solid $blue;
	transition: 0.3s all;  	
	background-color: white;
	color: $blue;
	font-size: rem(21);
	cursor: pointer;
	display: inline-block;
	position: relative;
	z-index: 1;
	border-radius: 50px;
	overflow: hidden;
	 @media #{$mobile} {
		font-size: rem(15);
	 }
	&:after{
		content: "";
		position: absolute;
		z-index: -1;
	    width: 0%;
	    height: 100%;
	    bottom: 0;
	    left: 0;
	    background: $blue;	
	    transition: all 0.3s;	
	}	 
    &:hover{
	  color:white;
	  border-color:$blue;
	  background-color:$blue;
      &:after{
		  width:100%;
		  background-color: $blue;
      }
      a{
      	color:white;
      }
    } 
}
.btn-secondory{
	padding: rem(10) rem(35);
	border:1px solid $orange;
	transition: 0.3s all;  	
	background-color: white;
	color: $orange;
	font-size: rem(16);
	cursor: pointer;
	display: inline-block;
	position: relative;
	z-index: 1;
	font-weight: 600;
	text-transform: uppercase;
	 @media #{$mobile} {
		font-size: rem(15);
	 }
	&:after{
		content: "";
		position: absolute;
		z-index: -1;
	    width: 0%;
	    height: 100%;
	    bottom: 0;
	    left: 0;
	    background: white;	
	    transition: all 0.3s;	
	}	 
    &:hover{
	  color:white;
	  border-color:$orange;
	  background-color:transparent;
      &:after{
		  width:100%;
		  background-color: $orange;
	  }
	}
}
.switch {
	position: relative;
	width: 45px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  }
  
  .switch-checkbox {
	display: none;
  }
  
  .switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: none;
	border-radius: 18px;
  }
  
  .switch-inner {
	width: 200%;
	margin-left: -100%;
	-moz-transition: margin 0.2s ease-in 0s;
	-webkit-transition: margin 0.2s ease-in 0s;
	-o-transition: margin 0.2s ease-in 0s;
	transition: margin 0.2s ease-in 0s;
  
	&:before, &:after {
	  float: left;
	  padding: 0;
	  width: 50%;
	  height: 18px;
	  line-height: 19px;
	  color: #a9a9a9;
	  font-family: 'FontAwesome';
	  font-weight: normal;
	  font-size: 11px;
	  -moz-box-sizing: border-box;
	  -webkit-box-sizing: border-box;
	  box-sizing: border-box;
	  border-radius: 18px;
	  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3) inset;
	}
  
	&:before {
	  content: "";
	  padding-left: 9px;
	  background-color: #5BB75B;
	  color: #444;
	  border-radius: 18px 0 0 18px;
	  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.25);
	}
  
	&:after {
	  content: "";
	  padding-right: 10px;
	  background-color: #b9b9b9;
	  text-align: right;
	  border-radius: 0 18px 18px 0;
	  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.25);
	}
  }
  
  .switch-thumb {
	width: 20px;
	margin: 0px;
	background-color: #F5F5F5;
	border: none;
	border-radius: 20px;
	position: absolute;
	top: -1px;
	bottom: 0;
	right: 25px;
	-moz-transition: all 0.2s ease-in 0s;
	-webkit-transition: all 0.2s ease-in 0s;
	-o-transition: all 0.2s ease-in 0s;
	transition: all 0.2s ease-in 0s;
	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fff), to(#d7d7d7));
	background-image: -webkit-linear-gradient(top, #fff, #d7d7d7);
	background-image: -o-linear-gradient(top, #fff, #d7d7d7);
	background-image: linear-gradient(to bottom, #fff, #d7d7d7);
	background-image: -moz-linear-gradient(top, #fff, #d7d7d7);
	background-repeat: repeat-x;
	filter: progid:dximagetransform.microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffd7d7d7', GradientType=0);
	filter: progid:dximagetransform.microsoft.gradient(enabled=false);
  
	&:hover {
	  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
	}
  }
  
  .switch-checkbox:checked + .switch-label {
	.switch-inner {
	  margin-left: 0;
	}
  
	.switch-thumb {
	  right: 0px;
	}
  }
  .btn-success{
	&:hover{
		background-color: transparent;
		color:#28a745 !important;
		border-color:#28a745 !important;
		&:before{
			background-color: transparent;
		}
	}
  }
  .btn-danger{
	&:hover{
		background-color: transparent;
		color:#dc3545 !important;
		border-color:#dc3545 !important;
		&:before{
			background-color: transparent;
		}
	}
  }
  .btn-warning{
	background-color: #ffae00;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	padding:12px 21px;
	color:white;
	&:hover{
		background-color: transparent;
		color:#ffae00 !important;
		&:before{
			background-color: transparent;
		}
	}
  }
  .btn-bleu{
	  background-color: $blue;
	  font-size: 12px;
	  font-weight: bold;
	  text-transform: uppercase;
	  padding:12px 21px;
	  color:white;
	  &:hover{
		background-color: transparent;
		color:$blue !important;
		border-color:$blue !important;
		&:before{
			background-color: transparent;
		}
	}
  }
  .btn-white{
	background-color: white;
	color:#481e06;
	border-color:$orange !important;
	font-weight: 500;
	border:2px solid $orange;
	&:not([href]):not([tabindex]){
		color:#481e06;
	}
	&:after{
		display: none;
	}  
	&:hover{
		border-color: white !important;
	}
  }
  .btn-transparent{
	  background-color: transparent;
	  border-color:$orange !important;
	  font-weight: 500;
	  border-width: 2px;
	  color:#481e06;
	  &:after{
		display: none;
	}
	&:hover{
		background-color: transparent;
		color:#fff;
		border-color: transparent !important;
		&:before{
			background-color: $orange;
		}
	}
	&.orange-txt{
		color:$orange;
	}
  }
  .btn-orange{
	background-color: $orange;
	color:white;
	border-color:$orange !important;
	font-weight: 500;
	&:not([href]):not([tabindex]){
		color:white;
	}
	&:after{
		display: none;
	}
	&:hover{
		background-color: transparent;
		color:#481e06;
		&:before{
			background-color: transparent;
		}
	}
}
.slick-arrow,.slick-slide{
	outline: none !important;
}
.btn-simple{
	font-weight: 500;
    color: #481e06;
    font-size: 16px;
    position: relative;
    display: inline-block;
    width: 102px;
    padding-bottom: 10px;
	&:before{
		content:"";
		width: 100%;
		position: absolute;
		height: 2px;
		background-color:  #481e06;
		bottom: 0;
	}
	&:after{
		content:"";
		width: 0%;
		position: absolute;
		height: 2px;
		background-color: $orange;
		bottom: 0;
		left:0;
		transition: 0.8s all; 
	}
	&:hover{
		&:after{
			width: 100%;
			z-index: 2;
		}
	}
}
  .btn{
		text-transform: uppercase;
		font-size: 13px;
		padding: 13px 35px;
		border-radius: 30px;
		position: relative;
		overflow: hidden;
		z-index: 1;
		@media #{$mobile} { 
			padding: 13px 20px;
			font-size: 12px;
		}
		&:before{
            content:"";
            position: absolute;
            overflow: hidden;
            z-index: 0;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            z-index: -1;
            background-color: $orange;
            border-radius: 50%;
            opacity: .9;
            transform: translate(-50%,-50%);
            transition: 425ms cubic-bezier(.5,0,.07,1);
        }
	  &.white{
		  color:white;
		  border-color:white;
	  }
	  
	&:after{
		content: "\e901";
		font-family: "icomoon";
		position: absolute;
		right: 20px;
		font-size: 7px;
		top: 50%;
		transform: translateY(-50%);
	}
	&:hover{
		color:white;
		border-color:$orange;
		&:before{
		width: 108%;
		height: 0;
		padding-top: 108%;
		opacity: 1;
		transition: 850ms cubic-bezier(0.5, 0, 0.07, 1);
		z-index: -1;
		}
	}
  }
  .btn-primary{
	background-color: $blue;
    padding: 13px 50px;
    font-size: 17px;
    border-color: #be5b70;
    font-family: 'Klavika-Medium';
	border-radius: 40px;
	text-transform: uppercase;
	&:before{
		content: "\e901";
		font-family: $icomoon;
	}
	&:disabled{
		opacity: .65;
		background-color: #9E9E9E;
		border-color: #9E9E9E;
	}
	&:active{
		background-color: $orange2 !important;
		border-color: $orange2 !important;
		color:white;
	}
	@media only screen and (max-width: 767px) {
		padding: 5px 26px;
	}
	&:hover,&:focus{
		background-color: $orange2;
		border-color: $orange2;
		color:white;
	}
  }
  .btn-default{
    padding: 13px 50px;
	font-size: 17px;
	border-color:#be5b70;
	color:#be5b70;
	font-family: 'Klavika-Medium';
	border-radius: 40px;
	text-transform: uppercase;
	margin-right: 30px;
	@media only screen and (max-width: 767px) {
		padding: 5px 26px;
	}
	&:hover,&:focus{
		background-color: #be5b70;
		color:white;
	}	  
  }
  .add{
	padding: 15px 25px 15px 40px;
    display: inline-block;
    width: auto;
    border: 1px solid #b4b4b4;
    border-radius: 40px;
    position: relative;
    background: #e96e14;
    color: white !important;
	border-color: white;
	transition: 0.3s all;  	
	text-transform: uppercase;
    font-size: 15px;
	&:before{
		content: "\e918";
		font-family: $icomoon;
		position: absolute;
		left: 18px;
		font-size: 16px;
	}
	&:hover{
		opacity: 0.7;
		color:white;
	}
  }
  .form-add{
	display: inline-block;
    width: 100%;
  }
  /* Pop */
@-webkit-keyframes hvr-pop {
	50% {
	  -webkit-transform: scale(1.2);
	  transform: scale(1.2);
	}
  }
  @keyframes hvr-pop {
	50% {
	  -webkit-transform: scale(1.2);
	  transform: scale(1.2);
	}
  }
  .hvr-pop {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .hvr-pop:hover, .hvr-pop:focus, .hvr-pop:active {
	-webkit-animation-name: hvr-pop;
	animation-name: hvr-pop;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
  }
  .file-field {
	position: relative;
	margin-bottom: 30px;
	padding-left: 11px;	
	label{
		font-size: 12px;
		padding-left: 30px;
	}
	.file-path-wrapper {
	  height: 48px;
	  padding-left: 0px;
	  overflow: hidden;
	  position: relative;
	  z-index: 0;
	  left: -45px;	  
	}
  
	input.file-path {
	  width: 100%;
	  border: 1px solid #C8CCD4;
	  background: #f8f8f8;
	  border-radius: 40px;
	  height: 47px;
	  padding-left: 59px;
	}
  
	.btn {
	  float: left;
	  border-radius: 38px;
	  position: relative;
	  z-index: 1;
	}
  
	span {
	  cursor: pointer;
	}
  
	input[type="file"] {
	  position: absolute;
	  top: 0;
	  right: 0;
	  bottom: 0;
	  left: 0;
	  width: 100%;
	  padding: 0;
	  margin: 0;
	  cursor: pointer;
	  filter: alpha(opacity = 0);
	  opacity: 0;
  
	  &::-webkit-file-upload-button {
		display: none;
	  }
	}
  }
  progress {
	/* Positioning */
	position: fixed;
	left: 0;
	top: 26px;
	display: none;
	/* Dimensions */
	width: 100%;
	height: .25em;
  
	/* Reset the apperance */  
	-webkit-appearance: none;
	   -moz-appearance: none;
			appearance: none;
  
	/* Get rid of the default border in Firefox/Opera. */
	border: none;
  
	/* For Firefox/IE10+ */
	background-color: transparent;
  
	/* For IE10+, color of the progress bar */
	color: $orange;
	z-index: 999999;
  }
  
  progress::-webkit-progress-bar {
	background-color: transparent;
  }
  
  .flat::-webkit-progress-value {
	background-color: $orange;
  }
  
  .flat::-moz-progress-bar {
	background-color: $orange;
  }
  
  .single::-webkit-progress-value {
	background-color: transparent;
	background-image: -webkit-linear-gradient(left, transparent, $orange);
  }
  
  .single::-moz-progress-bar {
	background-color: transparent;
	background-image: -moz-linear-gradient(left, transparent, $orange);
  }
  
  .multiple::-webkit-progress-value {
	background-image: -webkit-linear-gradient(-45deg, 
								 transparent 33%, rgba(0, 0, 0, .1) 33%, 
								 rgba(0,0, 0, .1) 66%, transparent 66%),
						-webkit-linear-gradient(left, $orange, $orange);
  }
  
  .multiple::-moz-progress-bar {
	background-image: -moz-linear-gradient(-45deg, 
								 transparent 33%, rgba(0, 0, 0, .1) 33%, 
								 rgba(0,0, 0, .1) 66%, transparent 66%),
						-moz-linear-gradient(left, $orange, $orange);
  }
  
  .progress-container {
	width: 100%;
	background-color: transparent;
	position: fixed;
	top: 0;
	left: 0;
	height: .25em;
	display: block;
  }
  
  .progress-bar {
	background-color: $orange;
	width: 50%;
	display: block;
	height: inherit;
  }
  /***loader1***/
  .loading{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.62);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  .loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 0.3em solid#F5F5F5;
	border-right: 0.3em solid #F5F5F5;
	border-bottom: 0.3em solid #F5F5F5;
	border-left: 0.3em solid $blue;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
	&:after{
		border-radius: 50%;
		width: 4em;
		height: 4em;
	}
  }
  @-webkit-keyframes load4 {
	0%,
	100% {
	  box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
	  box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
	  box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
	  box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
	  box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
  }
  @keyframes load4 {
	0%,
	100% {
	  box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
	  box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
	  box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
	  box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
	  box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
  }
  .content-loading-home{
	padding: 1.875rem;
    text-align: center;
    position: relative;
	min-height: 180px;
	width:100%;
    position: fixed;
    left: 0;
    right: 0;
    height: 100%;
	top: 0px;
	z-index: 9999;
	.loading2{
		background: rgba(255, 255, 255, 0.62);
		.loader2{
	
		}
	}
  }
  /** loader - 2 **/
  .content-loading{
	padding: 1.875rem;
    text-align: center;
    position: relative;
	min-height: 180px;
	width:100%;
  }
  .loading2{
	position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
	left: 0;
	top:50%;
	transform:translateY(-50%);
    display: flex;
    align-items: center;
	background-color:transparent;
  }
  .loader2,
.loader2:after {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.loader2 {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid#F5F5F5;
  border-right: 0.3em solid #F5F5F5;
  border-bottom: 0.3em solid #F5F5F5;
  border-left: 0.3em solid $blue;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/** loader 3 */
.loading3{
	position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
	left: 0;
	top:50%;
	transform:translateY(-50%);
    display: flex;
    align-items: center;
	background-color:transparent;
}
.loader3 {
	color: $blue;
	font-size: 5px;
	margin: 100px auto;
	width:7px;
	height: 7px;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: load4 1.3s infinite linear;
	animation: load4 1.3s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
	0%,
	100% {
	  box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
	  box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
	  box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
	  box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
	  box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
  }
  @keyframes load4 {
	0%,
	100% {
	  box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
	}
	12.5% {
	  box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	25% {
	  box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
	}
	37.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	50% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
	}
	62.5% {
	  box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
	}
	75% {
	  box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
	}
	87.5% {
	  box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
	}
  }
  /** loader 4 */
.loading4{
	position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
	left: 0;
	top:50%;
	transform:translateY(-50%);
    display: flex;
    align-items: center;
	background-color:transparent;
}
.loader4 {
	font-size: 10px;
	margin: 50px auto;
	text-indent: -9999em;
	width: 4em;
	height: 4em;
	border-radius: 50%;
	background: $blue;
	background: -moz-linear-gradient(left, $blue 10%, rgba(255, 255, 255, 0) 42%);
	background: -webkit-linear-gradient(left, $blue 10%, rgba(255, 255, 255, 0) 42%);
	background: -o-linear-gradient(left, $blue 10%, rgba(255, 255, 255, 0) 42%);
	background: -ms-linear-gradient(left, $blue 10%, rgba(255, 255, 255, 0) 42%);
	background: linear-gradient(to right, $blue 10%, rgba(255, 255, 255, 0) 42%);
	position: relative;
	-webkit-animation: load3 1.4s infinite linear;
	animation: load3 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
  }
  .loader4:before {
	width: 50%;
	height: 50%;
	background: $blue;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: '';
  }
  .loader4:after {
	background: #F1F3F6;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: '';
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
  }
  @-webkit-keyframes load3 {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes load3 {
	0% {
	  -webkit-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }