.siege{
	margin-bottom: 90px;
	.bg-map{
		background-color: white;
		box-shadow: 3px 1px 11px 1px rgba(171,171,171,1);
		padding: 15px;
	}
	.content{
		@extend .border-design;
		padding-left: 25px;
		padding-top: 40px;
		&:before{
			background-color: $black;
		}
		h3{
			font-size: 30px;
			margin-bottom: 30px;
			font-weight: 300;
		}
		ul{
			li{
				font-size: 16px;
				display: flex;
				margin-bottom: 15px;
				label{
					min-width: 125px;
					width: 125px;
				}
			}
		}
		.text{
			color:$black;
			text-transform: uppercase;
			a{
				color:$black;
				text-transform: uppercase;
				&:before{
					content: "\e90f";
					font-family: $icomoon;
					color:$black;
					font-size: 28px;
					position: relative;
					top: 6px;
					margin-right: 12px;
				}
			}
			span{
				color:$orange;
				text-decoration: underline;
			}
		}
	}
}