@media print {
	body {
		padding-top: 0;
	}
	.no-print,
	.no-print *,
	header,
	footer,
	.scroll-down,
	.btn-retour,
	.icon-share,
	.breadcrumb,
	#map,
	.b-festival .btn  {
		display: none !important;
	}
  .b-festival {
  	margin-bottom: 0 !important;
  	&__body {
    	margin-bottom: 10px !important;
    }
    .b-card__label {
    	width: 100% !important;
    	span {
    		padding-left: 0;
    		max-width: none !important;
    		flex: 0 0 auto !important;
    	}
    	span:nth-child(2) {
    		padding-left: 0;
    		padding-right: 0;
    	}
  	}
  }


  .container-festival {
  	margin-top: 0 !important;
  	margin-bottom: 0 !important;
  }
}