.nos-valeur{
	background-color: $gray--03;
	.title{
		@extend .border-design;
		&:before{
			background-color: $blue;
		}	
	}
	.caroussel-valeurs{
		display: flex;
		align-items: center;
		flex-flow: wrap;
		width: 100%;
		padding-top: 70px;
		position: relative;
		.slick-dots{
			position: absolute;
			left:0;
			right:0;
			bottom: -40px;
			display: flex;
			margin:0 auto;
			justify-content: center;
			li{
				margin: 0 5px;
				&.slick-active{
					button{
						background-color: $blue;
					}
				}
				button{
					border:0;
					width: 23px;
					height: 2px;
					background-color: #E1E1E1;
					border-radius: 30px;
					font-size: 0;
					padding: 0;
				}
			}
		}
		.content{
			.icon{
				text-align: center;
				margin-bottom: 40px;
				img{
					margin:0 auto;
				}
			}
			.text{
				text-align:center;
				h3{
					font-size:30px;
					text-transform:uppercase;
					margin-bottom: 10px;
				}
			}
		}
	}
}