.actualite-item{
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
	&:hover{
		.item:before{
			background-color: rgba(3, 86, 160, 0.55);
		}
		.image{
			img{
				transform: scale(1.1);
			}
		}
		.content{
			&:before{
				opacity: 0.7;
				transform: rotate3d(16, 0, 1, 53deg);
			}
			.lireplus{
				transition-delay: 0.4s;	
				opacity: 1;
				transform: translate3d(0,0,0);	
					&:before{
					
					}
					
				
			}
		}
	}
	.item{
		position: relative;
		overflow: hidden;
		&:before{
			content: "";
			background-color: rgba(0, 0, 0, 0.26);
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 0;
		}
	}
	.image{
		img{
			width: 100%;
			object-fit: cover;
			transition: 0.9s all;
			max-height: 390px;
			z-index: -1;
			position: relative;
			@media #{$mobile} { 
				max-height: 250px;
			}
		}
	}
	.content{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top:0;
		padding: 80px 50px 30px 30px;
		&:before{
			position: absolute;
			top: 40px;
			left: 0;
			width: 100%;
			height: 100%;
			background: url(#{$image}triangle.svg) no-repeat center center;
			background-size: 100% 100%;
			content: '';
			-webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
			transition: opacity 0.45s, transform 0.45s;
			transform: rotate3d(50, 2, 2, 90deg);
			-webkit-transform-origin: 0 100%;
			transform-origin: 0 100%;
		}
		@media #{$tablet_portrait} { 
			padding: 30px 12px 15px 12px;
		}
		@media #{$mobile} { 
			padding: 30px 12px 15px 12px;
		}
		.tag{
			font-size: 16px;
			color:$blue--03;
		}
		.lireplus{
			transition: opacity 0.35s, transform 0.35s;
			transform: translate3d(0,50px,0);
			opacity: 0;
			color:$blue;
			font-size: 14px;
			font-weight: 600;
			position: relative;
			padding-right: 10px;
			text-transform: uppercase;
			&:before{
				content: "\e91b";
				font-family: $icomoon;
				position: absolute;
				right: -18px;	
				font-size: 19px;
				top: -7px;			
			}
		}
		p{
			position: absolute;
			right: 0;
			bottom: 0;
			padding: 0 1.5em 7% 0;
			@media #{$tablet_portrait} { 
				padding: 0 1.5em 0 0;
			}
			@media #{$mobile} { 
				padding: 0 1.5em 0 0;
			}
		}
		h3{
			color:white;
			font-size: 28px;
			line-height: 1.3;
			margin-top: 25px;
			@media #{$tablet_portrait} { 
				font-size: 18px;
			}
			@media #{$mobile} {
				font-size: 18px;
			}
		}
		.date{
			color:white;
			font-size: 11px;
			position: absolute;
			bottom: 25px;
			
		}
	}
}
.alaune{
	margin-top: 30px;
	margin-bottom: 60px;
	@media #{$tablet_portrait} { 
		padding: 0 30px;
	}
	@media #{$mobile} { 
		padding: 0 30px;
	}
	.container .row{
		box-shadow: 0px 0px 12px 5px rgba(207,207,207,1);
		.col-md-7{
			padding-right: 0;
			@media #{$mobile} { 
				padding-left: 0;
			}
		}

	}
	.image{
		width: 100%;
		@media #{$tablet_portrait} { 
			height: 100%;
		}
		img{
			width: 100%;
			object-fit: cover;
			@media #{$tablet_portrait} { 
				height: 100%;
			}
		}
	}
	.content{
		padding: 40px 20px;
		.tag{
			font-size: 16px;
			color:$blue;
			margin-bottom: 20px;
			display: block;
		}
		h3{
			font-size: 28px;
			color:$black;
		}
		.date{
			font-size: 11px;
			color:$black;
			margin-bottom: 30px;
			display: block;
			margin-top: 10px;
		}
		p{
			font-size: 18px;
			color:$black;
		}
		a{
			margin-top: 40px;
		}
	}
	
}
.showall{
	width: 100%;
	a{
		margin: 25px auto 0;
		display: table;
		border-color: $blue;
		color:$blue !important;
		&:after{
			top: 17px;
			transform: none;
			transform: rotate(90deg);
		}
		&:hover{
			color:white !important;
		}
	}
}