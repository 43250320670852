[type="text"],
[type="email"],
[type="password"],
[type="tel"],
select,
.form-control {
	padding: 10px 10px;
	border: 1px solid transparentize($black, 0.59);
	font-size: 16px;
	color: $black;
	width: 100%;
	box-shadow: none;
	@include placeholder-color(#b7b7b7, 12px);
	outline-color: $orange;
	box-shadow: none;
	border-radius:8px;
	height: 45px;
	outline:none;
	
	&:focus {
		outline:none;
		border-color:$orange;
	}
	&.error {
		border-color:red;
		outline: transparent;
	}
	&.valid {
		background-size: 20px 17px;
		background-repeat: no-repeat;
		background-position: center right 10px;
		padding-right: 32px;
	}
}
textarea{
	padding: 10px 10px;
	border: 1px solid transparentize($black, 0.59);
	font-size: 16px;
	color: $black;
	width: 100%;
	box-shadow: none;
	@include placeholder-color(#b7b7b7, 12px);
	outline-color: $orange;
	box-shadow: none;
	border-radius:8px;
	height: 133px;
	outline:none;
}
select{
	background-image: url(#{$image}right-arrow.svg) !important;
	background-repeat: no-repeat !important;
	background-position: right 13px top 18px !important;
}
.field.select{
	border: 1px solid transparentize($black, 0.59);
	position: relative;
	padding-right: rem(0);
	&:before{
		content:"\f078";
		font-family: fontawesome;
		right:rem(10);
		position:absolute;
	}
	select{
		appearance: none;
		border:0;
		padding-right: rem(38);
		&:-ms-expand{
			display:none;
		}
	}
}
[type="submit"]{
	cursor: pointer;
	@extend .button--niveau1;
}
.field{
	position: relative;
	display:flex;
	align-items: center;
	flex-flow: wrap;
	margin-bottom:rem(20);
}
label {
	width: 100%;
	margin-bottom: rem(15);
	font-size: 16px;
	&.error {
		color: red;
		position: absolute;
		left: 0;
		bottom: rem(-20);
		font-size: rem(11);
	}
}
.valid-form{
	margin-top: 20px;
	.btn{
		margin: 0 5px;
		outline: none;
		border-radius: 39px;
	}
}
.require{
	color:red;
}
.actions-toolbar{
	margin-top:rem(10);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #713129;
    color: white;
}
.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #713129;
}
.select2-dropdown{
	background: #e96e14;
	padding: 15px;
	border:0;
	border-radius: 0;
	.select2-search--dropdown{
		input{
			border-radius: 50px;
			outline: none;
			padding-left: 15px;
		}
	}
	.select2-results{
		li{
			color:white;
		}
	}
}
.mdi-check{
	&:before{
		content: "\e909";
		font-family: $icomoon;
	}
}
.mdi-info-outline{
	&:before{
		content: "\e91c";
		font-family: $icomoon;
	}
}
.mdi-alert-triangle{
	&:before{
		content: "\e91e";
		font-family: $icomoon;
	}
}
.mdi-close-circle-o{
	&:before{
		content: "\e91d";
		font-family: $icomoon;
	}
}
.modal-main-icon.mdi.mdi-check,.modal-main-icon.mdi-info-outline,.mdi-alert-triangle,.mdi-close-circle-o{
	margin-bottom: 10px;
	display: block;
	font-size: 34px;
}
.modal-footer{
	justify-content: center;
}
.modal-header,.modal-footer{
	border:0;

	.close{
		order:1;
		color:white;
		opacity: 1;
	}
	h4{
		order:0;
		color:white;
		font-weight: 600;
		font-size: 20px;
	}
}
.confirmation-modal{
	.modal-body{
		padding-top: 30px;
	}
	h3{
		color:#481e06;
		font-size:24px;
		margin-bottom: 20px;
		font-weight: bold;
	}
	p{
		font-size:18px;
	}
	.modal-footer{
		button{
			min-width: 170px;
			&:hover{
				color:$orange;
			}
		}
	}
}
.modal-dialog {
    max-width: 500px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    left: 0;
	right: 0;
	width: 100%;
}
.select-effect {
	position: relative;
	&:before{
		content: "\f107";
		font-family: fontawesome;
		position: absolute;
		right: 39px;
		top: 15px;
		font-size: 16px;
		padding-right: 0px;
	}
	select{
		height: 48px;
		color: #999999;
		padding-left: 19px;
	}
}
select{
	appearance: none;
	outline: none;
}
sup{
	font-size: 13px;
    margin-left: 4px;
}
.custom-file{
	input{
		
	}
	label{
		height: 45px;
		border:1px solid rgba(57,57,57,0.41);
		border-radius: 8px;
		&:after{
			content: "\e913";
			font-family: "icomoon";
			font-size: 17px;
			background: transparent;
			border: 0;
			color: rgba(57,57,57,0.41);
		}
	}
}
.form-group{
	margin-bottom: 30px;
	label{
		color:#000;
		font-size: 16px;
		font-weight: normal;
	}
	.error{
	color: red;
    font-size: 11px;
    text-align: left;
    margin-top: 7px;
	}
}
.line__group-form{
	display: flex;
	flex-flow: wrap;
}
.line__radio-form{
	display: flex;
	&.gender{
		.radio-button{
			label{
				&:after{
					display: none !important;
				}
				&:before{
					border:0 !important;
					position: relative;
					top: 0px;
					left: 5px;
				}
			}
			&:first-child{
				label{
					&:before{
						content: "\e941";
						font-family: $icomoon;
					}
				}
			}
			&:nth-child(2){
				label{
					&:before{
						content: "\e942";
						font-family: $icomoon;
					}
				}
			}
		}
	}
	.radio-button{
		background-color: white;
		position: relative;
		border-radius: 30px;
		border: solid 1px #d3d0c9;
		margin-right: 10px;
		input[type='radio']:checked+label{
			color:$orange;
		}
		label{
			padding: 20px 30px 16px 16px;
			margin-bottom: 0;
			cursor: pointer;
		}

	}
}
.content-form{
	input[type='text']{
		border-radius: 30px;
		border: solid 1px #d3d0c9;
		background-color: #ffffff;
		height: 60px;
		padding-left: 22px;
	}
}
.checkbox-button{
	position: relative;
	cursor: pointer;
	margin-bottom: 25px;
	padding-left: 0;
	input[type='checkbox']{
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		&:checked{
			+ label{
				color:$blue;
				&:before{
					content: "\e928";
					border-color:$blue;
				}
			}
		}
	}
	label{
		display: flex;
		align-items: center;
		font-size: 16px;
		&:before{
			content: "";
			min-width: 22px;
			min-height: 22px;
			max-width: 22px;
			max-height: 22px;
			border:1px solid $black2;
			border-radius: 2px;
			display: block;
			margin-right: 10px;
			font-family: $icomoon;
			font-size: 11px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;
		}
	}	
}
.radio-button{
	position: relative;
	cursor: pointer;
	input[type='radio']{
		opacity: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		&:checked{
			+ label{
				color:$blue;
				&:before{
					border-color:$orange;
				}
				&:after{
					content: "";
					width: 8px;
					height: 8px;
					background-color: $orange;
					border-radius: 50%;
					display: block;
					position: absolute;
					left: 21px;
					@media all and (-ms-high-contrast:none)
					{
						top: 25px;
					*::-ms-backdrop { top: 25px; }
					}
				}
			}
		}
	}
	label{
		display: flex;
		align-items: center;
		min-width: 125px;
		&:before{
			content: "";
			width: 18px;
			height: 18px;
			border:1px solid $black2;
			border-radius: 50%;
			display: block;
			margin-right: 10px;
		}
	}
}
.form-contact{
	@extend .border-design;
	&:before{
		background-color: $blue;
	}
	h3{
		font-size: 30px;
		font-weight: 300;
		margin-bottom: 50px;
	}
}
.map{
	position: relative;
	.coordonne{
		position: absolute;
		right:10%;
		transform: translateY(-50%);
		top:50%;
		background-color: rgba(255, 255, 255, 0.8);
		font-size: 19px;
		width: 430px;
		z-index: 9;
		padding: 25px 30px;
		color:$blue;
		@media #{$mobile} {
			position: relative;
			right:0;
			top:0;
			transform: none;
			width: 100%;
		}
		> div{
			margin-bottom: 20px;
			line-height: 1.4;
			display: flex;
		}
		.adresse{
			&:before{
				content: "\e90c";
				font-family: $icomoon;
				margin-right: 9px;
			}
		}
		.telephone{
			&:before{
				content: "\e90d";
				font-family: $icomoon;
				margin-right: 9px;
			}
		}
		.fax{
			&:before{
				content: "\e90e";
				font-family: $icomoon;
				margin-right: 9px;
				font-size: 14px;
				position: relative;
				top: 5px;
			}
		}
	}
}
.form{
	.action{
		justify-content: space-between;
		display: flex;
		.button-primary{
			padding: 14px 50px 14px 30px;
			&:before{
				content: "\e90a";
				font-family: $icomoon;
				position: absolute;
				right:15px;
				font-size: 13px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.button-default{
			padding: 14px 50px 14px 30px;
			&:before{
				content: "\e90b";
				font-family: $icomoon;
				position: absolute;
				right:15px;
				font-size: 13px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		
	}
	.description{
		font-size: 16px;
	}
	.form-group{
		margin-bottom: 40px;
		position: relative;
		z-index: 1;
	}
	.form-row{
		justify-content: space-between;
		.form-group{
			&.col-md-6{
				max-width: 46%;
				@media #{$mobile} { 
					max-width: 100%;
				}
			}
		}
	}
}
.list-contact{
	ul{
		li{
			margin-bottom: 20px;
			a{
				font-size: 15px;
				color:$black;
				display: flex;
				align-items: center;
				overflow: hidden;
				&.active{
					color:$blue;
					padding-left: 0;
					&:before{
						left:0;
						margin-right: 10px;
						margin-left: 0px;
					}
				}
				&:hover{
					color:$blue;
					padding-left: 0;
					&:before{
						left:0;
						margin-right: 10px;
						margin-left: 0px;
					}
				}
				&:before{
					content: "\e901";
					font-family: "icomoon";
					position: relative;
					left: -19px;
					transition: all 0.5s ease;
					color:$blue;
					font-size: 7px;
					margin-left: -17px;
				}
			}
		}
	}
}