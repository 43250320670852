.caroussel-home{
    position: relative;
    .slick-dots{
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        display: flex !important;
        justify-content: center;
        li{
            margin: 0 3px;
            button{
                background-color: transparent;
                border:1.5px solid white;
                border-radius: 50px;
                width: 10px;
                height:10px;
                font-size:0;
                padding:0;
                outline: none;
                transition: 0.8s all;  
            }
            &.slick-active{
                button{
                    width:20px;
                    background-color: white;
                }
            }
        }
    }
    .slide-list{
        position: relative;
    }
    .image{
        &.small-height-img{
            img{
                max-height: 440px;
            }
        }
        img{
            width: 100%;
            object-fit: cover;
            @media #{$mobile} { 
                height:400px;
            }
        }
    }
    .text{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        z-index: 1;
        max-width: 1200px;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-right: 600px;
        @media #{$tablet_landscape_max_width} { 
            padding-right: 15px;
        }
        h2{
            font-size: 44px;
            color: white;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 20px;
            @media #{$mobile} { 
                font-size:30px;
                line-height: 1.2;
            }
        }
        p{
            font-size:18px;
            color:white;
        }
        a{
            margin-top: 20px;
            &:after{
                display: none;
            }
        }
    }
    .text-center{
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
        max-width: 800px;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-right: 15px;
        @media #{$tablet_landscape_max_width} { 
            padding-right: 15px;
        }
        h2{
            font-size: 44px;
            color: white;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 20px;
            @media #{$mobile} { 
                font-size:30px;
                line-height: 1.2;
            }
        }
        p{
            font-size:18px;
            color:white;
        }
        a{
            margin-top: 20px;
            &:after{
                display: none;
            }
        }
    }
}
.slider-accueil{
    position: relative;
}
.scrolltobottom{
    position: absolute;
    bottom: -26px;
    width: 50px;
    height: 50px;
    background-color: #f4f3f0;
    border-radius: 50px;
    left:0;
    right:0;
    margin:0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:before{
        content: "\e915";
        font-family: $icomoon;
        color:$orange;
        margin: 0 auto;
        font-size: 22px;
        position: relative;
        left: 2px;    
    }
}
.presentation{
    background-color: #f4f3f0;
    padding: 80px 0;
    @media #{$mobile} { 
        padding:30px 0;
    }
    p{
        text-align: center;
        text-transform: uppercase;
        color: #481e06;
        font-size: 28px;
        padding: 0 50px;
        @media #{$mobile} { 
            padding:0 15px;
            font-size:18px;
        }
        br{
            @media #{$tablet_portrait_max_width} { 
                display: none;
            }
        }
    }
}
.nos-services{
    padding-top: 70px;
    padding-bottom:50px;
    @media #{$mobile} { 
        padding:40px 0;
    }
    .services{
        margin-top: 50px;
        .col-md-3{
            border-right: 1px solid #d5d5d5;
            @media #{$mobile} { 
                margin-bottom: 30px;
                border-right: 0;
                width: 50%;
            }
            &:last-child{
                border-right: 0;
            }
        }
        .icon{
            margin: 0 auto 10px;
            max-width: 75px;
        }
        p,a{
            font-size:23px;
            font-weight: 500;
            color:#481e06;
            text-align: center;
            line-height: 1.2;
            width: 100%;
            display: block;
            @media #{$mobile} { 
                font-size:18px;
            }
        }
    }
}
.produits{
    background-color: #f4f3f0;
    padding-top: 80px;
    .row__produit{
        display: flex;
        flex-flow: wrap;
        margin-bottom: 80px;
        width: 100%;
        &:hover{
            .image{
                .icon{
                    display: flex;
                }
                
            }
            .content .btn{
                color:white;
                &::before{
                    background-color: $orange;
                    width: 108%;
                    height: 0;
                    padding-top: 108%;
                    opacity: 1;
                    -webkit-transition: 850ms cubic-bezier(0.5, 0, 0.07, 1);
                    transition: 850ms cubic-bezier(0.5, 0, 0.07, 1);
                    z-index: -1;
                }
            }
        }

        .image{
            position: relative;
            transition: 0.4s all;  
            .icon{
                display: none;
                position: absolute;
                top: 0;
                width: 100%;
                transition: 0.4s all; 
                align-items: center;
                justify-content: center;
                background: rgba(216,128,56,0.5);
                height: 100%;
                img{
                    width: 65px;
                    height: auto;
                }
            }
            @media #{$mobile} { 
                margin-bottom: 40px;
            }
            &:hover{

            }
            img{
                position: relative;
                width: 100%;
                object-fit: cover;
                transition: 0.4s all; 
                height: 270px;
            }
            &:before{
                content: "";
                background-color: white;
                width: 100%;
                height: 100%;
                position: absolute;
                left: -14px;
                z-index: 0;
                top: 13px;
                transition: 0.4s all;           
            }
        }
    }
    .list{
        margin-top: 60px;
        @media #{$tablet_portrait_max_width} { 
        }
        @media #{$mobile} { 
        }
        .col-md-6{
            @media #{$mobile} { 
                padding-left:0;
                padding-right:0;
            }
            &:nth-child(2){
                padding-left: 60px;
                @media #{$mobile} { 
                    padding-left:0;
                    padding-top:0px;
                }
            }
        }
        .content{
            height: 100%;
            margin-top: 48px;
            @media #{$mobile} { 
                align-items: center;
            }
            .btn{
                margin: 0 auto;
                display: table;
            }
            .icon{
                max-width: 90px;
                margin-bottom: 30px;
                @media #{$mobile} { 
                    max-width: 80px;
                    width: 80px;
                }
                img{
                    width: 90px;
                    height: 90px;
                }
            }
            h3{
                font-size: 26px;
                color: #481e06;
                text-transform: none;
                margin-bottom: 16px;
                font-weight: 700;
                text-align: center;
                @media #{$mobile} { 
                    font-size:22px;
                }
            }
            p{
                font-size:15px;
                margin-bottom: 20px;
                text-align: center;
                min-height: 90px;
            }
        }
    }
}
.autoentrepeneur{
    background: url(#{$image}bg-marron.png) no-repeat center center;
    @media #{$mobile} { 
        padding-bottom: 40px;
        background: url(#{$image}bg-marron.png) no-repeat top;
    }
    .image{
        @media #{$mobile} { 
            margin-bottom: 40px;
        }
        img{
            position: relative;
            top: 4px;
            @media #{$mobile} { 
                max-width: 280px;
            }
        }
    }
    .content{
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        h2{
            text-align: left;
            &:after{
                right: auto;
                left: 0;
                margin: 15px 0 0;
            }
        }
        a{
            max-width: 170px;
            margin-top: 30px;
        }
    }
}
.trouveuneagence{
    padding-top: 100px;
    background-color: #f4f3f0;
    background-image: url(#{$image}trouvez-map.png);
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 100px;
    .btn-orange{
        &:hover{
            color:$orange;
        }
    }
    .form{
        max-width: 570px;
        margin: 55px auto 0;
        .form-group{
        text-align: center;
            input[type="text"]{
                height: 60px;
                border-radius: 50px;
                padding: 0 55px;
                border:0;
                box-shadow: 0px -1px 15px 1px rgba(209,209,209,1);

            }
            button{
                position: absolute;
                right: 0;
                background-color: $orange;
                font-weight: 500;
                font-size: 16px;
                height: 40px;
                margin-top: 10px;
                margin-right: 12px;
                padding: 10px 61px;
                @media #{$mobile} { 
                    padding:10px 20px;
                }
            }
        }
    }
}
.besoin-aide{
    padding-top: 80px;
    padding-bottom: 40px;
    .list{
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        margin-top: 40px;
        .bloc-1{
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            border:5px solid #dcdce1;
            border-radius: 20px;
            width: 30%;
            padding-top: 60px;
            padding-bottom: 45px;
            padding-left: 15px;
            padding-right: 15px;
            background-color:white;
            @media #{$mobile} { 
                width: 100%;
                margin-bottom: 30px;
                position: relative;
            }
            small{
                color:#481e06;
                font-size: 10px;
                text-align: center;
                margin-top: 10px;
                position: absolute;
                bottom: 24px;
                left: 0;
                right: 0;
                margin: 0 auto;
                @media #{$mobile} { 
                    bottom:13px;
                }
            }
            a{
                padding: 13px 65px;
                text-transform: none;
                font-weight: 500;
                font-size:16px;
                @media #{$tablet_portrait_max_width} { 
                    padding:13px 30px;
                }
                &.btn-orange{
                    &:hover{
                        color:$orange;
                    }
                }
            }
            .icon{
                max-width: 90px;
                margin-bottom: 30px;
                img{
                    width: 90px;
                    height:100px
                }
            }
            h3{
                color:#481e06;
                font-weight: 700;
                font-size:26px;
                margin-bottom: 50px;
                text-align: center;
                padding: 0 19px;
                white-space: normal;
                 word-break: break-word;
                 width: 100%;
                @media #{$tablet_portrait_max_width} { 
                    font-size:18px;
                }
            }
        }
    }
}
.content-form{
    border-radius: 15px;
    box-shadow: 0.9px 1.8px 10px 0 rgba(211, 208, 201, 0.3);
    border: solid 1px #d3d0c9;
    background-color: #f4f3f0;
    max-width: 830px;
    margin: 0 auto;
    padding: 50px;
    @media #{$mobile} { 
        padding: 20px;
        .form-group{
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.recap-etap{
        max-width: 1030px;
    }
}
.title-step{
    color:#481e06;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 30px;
    &:after{
        content: "";
        width: 60.3px;
        height: 5.1px;
        border-radius: 2.5px;
        background-color: #e67900;
        display: block;
        margin-top: 12px;
    }
}
.text-modalite{
    padding: 30px 0;
    border-top: 1px solid #d3d0c9;
    border-bottom: 1px solid #d3d0c9;
    margin-top: 30px;
    margin-bottom: 20px;
    p{
        margin-bottom: 0;
        font-size: 14px;
    }
}
.title-info{
    font-size: 22px;
    color:$orange;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 20px;
    width: 100%;
}
.infopersonnel{
    border-radius: 15px;
    background-color: #ffffff;
    padding:20px;
    margin-left: -3%;
    width: 106%;
    .content{
    display: flex;
    justify-content: space-between;
    flex-flow:wrap;
    padding: 0 15px;
    margin-bottom: 20px;
    @media #{$mobile} {
        padding-left: 0;
        padding-right: 0;
    }
    .colonne{
        padding: 0 30px;
        width: 50%;
        @media #{$mobile} {
            width: 100%;
            padding: 0 !important;
        }
        &:nth-of-type(1){
            padding-left: 0;
        }
        &:nth-of-type(2){
            padding-right: 0;
        }
        &.green{
            .form-text{
                span{
                    color:#23788c;
                }
            }
        }
    }
    }
    .form-text{
        display: flex;
        border-bottom: solid 1px #d3d0c9;
        margin: 0;
        align-items: center;
        padding: 15px 0;
        justify-content: space-between;
        label{
            color:#2f2c2a;
            font-size:16px;
            margin-bottom: 0;
            width: 67%;
        }
        span{
            font-weight: 500;
            color:#000;
            text-align:right;
        }
    }
}
.wizard-inner{
    .wizard{
        padding-top: 40px;
        border-top:1px solid #d3d0c9;
        margin-bottom: 50px;
        .tab-pane{
            @media #{$mobile} {
                padding:0 15px;
            }
        }
        .title-top-wizard{
            text-align: center;
            font-size: 30px;
            color:#481e06;
            margin-bottom: 40px;
        }
        .action{
            display: flex;
            justify-content: center;
            border-top: 1px solid #d3d0c9;
            padding-top: 31px;
            .btn{
                margin: 0 5px;
            }
            .btn-orange{
                &:hover{
                    color:$orange;
                }
            }
        }
    }
    .step-header{
        background-color: #f4f3f0;
        ul{
            display: flex;
            justify-content: center;
            padding: 28px 15px;
            li{
                width: 20%;
                position: relative;
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    content: "";
                    background-color: #d9d9d9;
                    position: absolute;
                    right: -76px;
                    height: 1px;
                    width: 70%;
                    top: 17px;
                    @media #{$mobile} {
                        right: -8px;
                        height: 1px;
                        width: 30%;
                        top: 20px;
                    }
                }
                &.active{
                    a{
                        color:white;
                        span{
                            background-color: $orange;
                            border-color: $orange;
                        }
                        label{
                            color:$orange;
                        }
                    }
                }
                a{
                    display: flex;
                    align-items: center;
                    flex-flow: column;
                    color:#d3d0c9;
                    font-size:16px;
                    text-transform: uppercase;
                    text-align: center;
                    justify-content: center;
                    span{
                        border: solid 2px #d3d0c9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 500;
                        border-radius: 100px;
                        width: 40px;
                        height: 40px;
                        margin-bottom: 20px;
                    }
                    label{
                        margin-bottom: 0;
                        @media #{$mobile} {
                            font-size: 0;
                        }
                    }
                }
            }            
        }
    }
}
.item-cycle{
    .itm{
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 13px;
        overflow: hidden;
        height: 100%;
        background: white;
        @media #{$mobile} {
            margin-bottom: 20px;
        }
    }
    .image{
        position: relative;
        img{
            height: 200px;
            width: 100%;
            object-fit: cover;
        }
        .btn{
            position: absolute;
            left: 0;
            right: 0;
            bottom: -23px;
            margin: 0 auto;
            display: flex;
            width: 50%;
            justify-content: center;
            align-items: center;
            &:hover{
                color:$orange;
                &:before{
                    background-color:#fff;
                }
            }
        }
    }
    .content{
        background-color: white;
        padding: 40px 60px;
        text-align: center;
        width: 100%;
        padding-top: 60px;
        h4{
            color:#481e06;
            text-align: center;
            width: 100%;
            font-weight: 500;
            margin-bottom: 10px;
            font-size: 18px;
        }
        p{
            color:#262626;
            font-size:14px;
        }
    }
}
.item-acco{
    text-align: center;
    margin-bottom: 20px;
    @media #{$mobile} { 

    }
    &.col-lg-3{
        .image{
            img{
                height: 220px;
            }
            h4{
                font-size: 21px;
                padding: 0 15px;
            }
        }
        .content{
            p{
                padding: 0 10px;
            }
        }
    }
    .content{
        background-color: #f4f3f0;
        padding: 22px 17px;
        border-radius: 0px 0px 25px 25px;
        p{
            min-height: 90px;
            padding: 0 37px;
        }
    }
    .image{
        background-color: $orange;
        position: relative;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 275px;
            object-fit: cover;
        }
        h4{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            left:0;
            right:0;
            margin:0 auto;
            text-align:center;
            font-size: 24px;
            color: white;
            font-weight: 700;
            line-height: 1.5;
        }
    }
}
.description-top-content{
    max-width: 820px;
    margin: 0 auto 40px;
    padding:0 15px;
    @media #{$mobile} { 
        
    }
    h3{
        margin-bottom: 35px;
    }
    p{

    }
}
.bloc--3{
    @media #{$mobile} {
        padding-bottom: 20px;
    }
}
.bloc-question{
    padding-top: 45px;
    padding-bottom: 45px;
    h3{
        margin-bottom: 50px;
    }
    .card{
        border:0;
        border-bottom:1px solid #d3d0c9;
        .card-header{
            background-color: transparent;
            border:0;
            padding: 20px 0;
            position: relative;
            outline:none;
            &:focus{
                box-shadow: none !important;
                outline:none;
            }
            &:before{
                content: "\e925";
                font-family: 'icomoon';
                position: absolute;
                right: 15px;
                transform: rotate(90deg);
                font-size: 13px;
                top: 27px;
                color:$orange;
            }
            h5{
                outline:none;
                &:focus{
                    box-shadow: none !important;
                    outline:none;
                }
                button{
                    background-color: transparent;
                    color:#23788c;
                    font-size:22px;
                    border:0;
                    box-shadow: none !important;
                    outline:none;
                    width: 100%;
                    text-align: left;
                    &:focus{
                        box-shadow: none !important;
                        outline:none;
                    }
                }
            }
        }
    }
}
.list-st{
    display: flex;
    justify-content: center;
    margin: 40px 0;
    width: 100%;
    flex-flow: wrap;
    @media #{$mobile} { 
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    li{
        width: 25%;
        height: 220px;
        padding: 0 30px;
        position: relative;

        &:hover{
            .content{
                background: $orange;
                cursor: pointer;
                .text{
                    visibility: visible;
                }
                a{
                    color:white !important;
                }
                svg{
                    max-width: 37px;
                    margin-bottom: 10px;
                    height: 45px;
                    margin-top: -117px;
                    path{
                        fill:white;
                    }
                }
                h4{
                    color:white; 
                }
            }
        }
        &.active-item{
            .content{
                background: $orange;
                cursor: pointer;
                .text{
                    visibility: visible;
                }
                a{
                    color:white !important;
                }
                svg{
                    max-width: 37px;
                    margin-bottom: 10px;
                    height: 45px;
                    margin-top: -117px;
                    path{
                        fill:white !important;
                    }
                }
                h4{
                    color:white;
                }
            }
        }
        @media #{$tablet_landscape_max_width} {

        }
        @media #{$tablet_portrait_max_width} {
            width: 38%;
            margin-bottom: 20px;
        }
        @media #{$mobile} { 
            width: 100%;
            margin-bottom: 40px;
            max-width: 280px;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
        &:after{
            content: "\e925";
            font-family: 'icomoon';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            color:#481e06;
            @media #{$tablet_portrait_max_width} {
                display: none;
            }
            @media #{$mobile} { 
                display: flex;
                position: absolute;
                bottom: -161px;
                align-items: flex-end;
                top: auto;
                left: 0;
                right: 0;
                margin: 0 auto;
                transform: rotate(90deg);
            }
        }
        .content{
            border: solid 4px $orange;
            border-radius: 156px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            flex-flow: column;
            justify-content: center;
            padding: 0 15px;
            position: relative;
            transition: 0.3s all;  
            &:hover{
                background: $orange;
                cursor: pointer;
                .text{

                }
                svg{
                    path{
                        fill:#fff !important;
                    }
                }
                h4{
                    color:white;
                }

            }
            img,svg{
                max-width: 75px;
                margin-bottom: 23px;
            }
            h4{
                color:#481e06;
                font-size:22px;
                text-transform: uppercase;
                font-weight: 600;
            }
            .text{
                visibility: hidden;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                border-radius: 27px;
                padding: 22px;
                text-align: center;
                color: white; 
                display: flex;
                align-items: center;
                flex-flow: column;
                justify-content: center;
                padding-top: 75px;
                p{
                    font-size: 12px;
                    line-height: 1.3;
                    margin-top: 16px;
                }
                a{
                    margin-top: 10px;
                    background: transparent;
                    border: 0;
                    padding: 0;
                    color:$orange;
                    text-decoration:underline;
                    &:hover{
                        color:white;
                        &:before{
                           display: none;
                        }
                    }
                }
            }
        }
        @media #{$tablet_portrait_max_width} { 
            .content{
                background: $orange;
                cursor: pointer;
                .text{
                    visibility: visible;
                    p{
                        color:white;
                    }
                }
                a{
                    color:white !important;
                }
                svg{
                    max-width: 37px;
                    margin-bottom: 10px;
                    height: 45px;
                    margin-top: -117px;
                    path{
                        fill:white;
                    }
                }
                h4{
                    color:white; 
                }
            }

        }
    }
}
.bloc-alert-info{
    background-color:white;
    padding-top: 40px;
    padding-bottom: 40px;
    .content{
        border-radius: 10px;
        border: solid 1px #e67e04;
        background-color: rgba(230, 126, 4, 0.05);
        max-width: 900px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding-left: 45px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 88px;
        @media #{$mobile} {
            padding:10px 10px;
        }
        .icon-alert-info{
            background: url(#{$image}infos.svg) no-repeat;
            width: 65px;
            height: 65px;
            margin-right: 40px;
            min-width: 65px;
        }
        p{
            font-size: 18px;
            color:#481e06;
            @media #{$mobile} {
                font-size:14px;
            }
        }
    }
}
.hidden-bloc{
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
.show-bloc{
    max-height: 3000px;
    transition: max-height 0.6s ease-in;
    overflow: hidden;
}
.bloc-edation{
    background-color: #f4f3f0;
    .row{
        padding-top: 60px;
        padding-bottom: 40px;
    }
    h3{
        color:#e67a02;
        font-weight: 600;
        width: 100%;
        text-align: center;
        font-size: 40px;
        margin-bottom: 30px;
    }
    .list{
        display: flex;
        justify-content: center;
        width: 100%;
        flex-flow: wrap;
        transition: 0.5s all; 
        @media #{$mobile} { 

        }
        .item{
            width: 29%;
            height: auto;
            border-radius: 20px;
            border: solid 6px #dcdce1;
            background-color: #ffffff;
            margin: 0 20px;
            padding: 60px 29px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            min-height: 387px;
            overflow: hidden;
            @media #{$tablet_portrait_max_width} {
                width: 28%;
            }
            @media #{$mobile} { 
                width: 100%;
                margin-bottom: 20px;
            }
            &:hover{
                img{
                    display: none;
                }
                .content-txt{
					max-height: 500px;
					transition: max-height 1.8s ease-in;
                }
            }

            img{
                margin-bottom: 50px;
            }
            h4{
                color:#481e06;
                font-weight: 600;
                font-size:22px;
                line-height: 1.4;
                @media #{$tablet_portrait_max_width} {
                    font-size: 18px;
                }
            }
            .content-txt{
				max-height: 0;
				transition: max-height 0.1s ease-out;
                overflow: hidden;
                ul{
                    margin-bottom: 30px;
                    margin-top: 20px;                    
                    li{
                        color:#545454;
                        font-size:12px;
                        text-align:left;
                        display: flex;
                        margin-bottom: 10px;
                        align-items: center;
                        &:before{
                            content: "";
                            width: 7px;
                            height:7px;
                            min-width: 7px;
                            min-height:7px;
                            background-color: #e66c00;
                            border-radius: 120px;
                            display: block;
                            margin-right: 10px;
                        }
                    }
                }
                .btn-white{
                    &:hover{
                        color:white;
                    }
                }
            }
            @media #{$tablet_portrait_max_width} {
                padding: 10px 15px;
                img{
                    display: block;
                    height: 60px;
                    margin-bottom: 10px;
                }
                .content-txt{
					max-height: 500px;
					transition: max-height 1.8s ease-in;
                }
            }
        }
    }
}
.description-interne{
    .h3{
        width: 100%;
    }
    p{
        max-width: 700px;
        margin: 0 auto 20px;
        text-align: center;
        padding: 0 15px;
        
    }
}
.scrolltopbottom{
    text-align: center;
    display: flex;
    justify-content: center;
    .btn-scroll{
        background: white;
        border: 0;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        transform: rotate(90deg);
        position: relative;
        top: -18px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:focus{
            outline: none;
            box-shadow: none;
        }
        &:before{
            content: "\e925";
            font-family: 'icomoon';
            position: relative;
            top: 0px;
            left: 10px;
            color:$orange;
            animation: bounce 0.9s ease infinite;
        }
    }
}
@keyframes bounce{
    from {left: 10px;}
    50%  {left: 5px;}
    to   {left: 10px;}
}
.bloc-ccg{
    padding-top: 60px;
    padding-bottom: 60px;
    h3{
        text-align: center;
        font-size: 30px;
        color:#481e06;
        width: 100%;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .list{
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        @media #{$mobile} { 

        }
        .item{
            width: 33%;
            height: 168.5px;
            margin-bottom: 30px;
            padding: 0 20px;
            @media #{$mobile} { 
                width: 100%;
            }
            .content{
                border-radius: 18px;
                box-shadow: 0.9px 1.8px 10px 0 rgba(0, 0, 0, 0.14);
                background-color: #ffffff;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 15px;
            }
        }        
    }
}
.besoin-statup{
    padding-top: 200px;
    padding-bottom: 270px;
    @media #{$mobile} { 
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .ronde{
        width: 550px;
        height: 550px;
        border: solid 30px #23788c;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 300px;
        margin:0 auto;
        position: relative;
        @media #{$mobile} { 
            width: 100%;
            height: 100%;
            border: 0;
            display: inline-block;
        }
        .title{
            font-weight: 900;
            color: #23788c;
            font-size: 50px;
            text-align: center;
            padding: 0 50px;
            @media #{$mobile} { 
                margin-bottom: 30px;
            }
        }
        ul{
            position: absolute;
            width: 100%;
            height: 100%;
            @media #{$mobile} { 
                position: relative;
            }
            li{
                
                color:white;
                width: 220px;
                height: 220px;
                border-radius: 420px;
                display: flex;
                align-items: center;
                flex-flow: column;
                justify-content: center;
                @media #{$mobile} { 
                    position: relative !important;
                    top:0 !important;
                    left:0 !important;
                    right:0 !important;
                    bottom:0 !important;
                    margin:0 auto 20px !important;
                }
                .plus{
                    &:before{
                        content: "+";
                        color: #e67900;
                        font-size: 35px;
                        position: absolute;
                        border: 2px solid #e67900;
                        width: 35px;
                        height: 35px;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: normal;
                        left: 0;
                        right: 0;
                        margin: 11px AUTO 0;
                    }
                }
                &:first-child{
                    position: absolute;
                    top: -185px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
                &:nth-child(2){
                    position: absolute;
                    top: 47px;
                    left: -107px;
                }
                &:nth-child(3){
                    position: absolute;
                    top: 36px;
                    right: -143px;
                }
                &:nth-child(4){
                    position: absolute;
                    left: 0;
                    right: -438px;
                    margin: 0 auto;
                    bottom: -76px;
                }
                &:nth-child(5){
                    position: absolute;
                    left: -76px;
                    bottom: -81px;
                }
                .flip-card {
                    -webkit-perspective: 1000px;
                    perspective: 1000px;
                    width: 100%;
                    height: 100%;		
                    .flip-card-inner{
                        position: relative;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        transition: 0.3s all; 
                        .flip-card-front{
                            border-radius: 420px;
                            display: flex;
                            align-items: center;
                            flex-flow: column;
                            justify-content: center;
                            background-color: #e67900;
                            transition: 0.3s all; 
                        }
                        .flip-card-front,.flip-card-back{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            backface-visibility: hidden;
                            p{
                                text-align: center;
                            }
                        }
                        .flip-card-back{
                            background-color: #f4f3f0;
                            border-radius: 420px;
                            color: white;
                            transform: rotateY(180deg);
                            display: flex;
                            align-items: center;
                            flex-flow: column;
                            justify-content: center;
                            padding: 0 20px;
                            line-height: 1.5;
                            border:12px solid #e67900;
                            p{
                                color:#481e06;
                                font-size: 18px;
                            }
                        }
                    }
                    &:hover{
                        .flip-card-front{
                            transform: scale(1.08);
                        }
                    }
                  }
                .title{
                    font-size: 20px;
                    color: white;
                    font-weight: normal;
                    padding: 0 30px;
                    line-height: 1.2;
                }
                img{
                    height: 60px;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
.demande-rendezvous{
    background-color: #f4f3f0;
    .row{
        background: url(#{$image}bg-rendezvous.png) no-repeat top right;
        background-size: contain;
    }
    .content{
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        padding-top: 90px;
        padding-bottom: 90px;
        @media #{$mobile} { 
            padding-top: 30px;
            padding-bottom: 30px;
        }
        span{
            font-weight: 500;
            color:#481e06;
            font-size: 18px;
            display: block;
            width: 100%;
            margin-bottom: 18px;
        }
        .title{
            color: #481e06;
            font-weight: 900;
            font-size: 28px;
            line-height: 1.4;
            margin-bottom: 25px;

        }
    }
    .list{
        display: flex;
        flex-flow: wrap;
        padding-top: 90px;
        padding-bottom: 90px;
        @media #{$mobile} { 
            padding-top: 30px;
            padding-bottom: 30px;
        }
        li{
            width: 33%;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            @media #{$mobile} { 
                width: 100%;
                margin-bottom: 30px;
            }
            &.rendezvousagence{
                .title{
                    font-size:36px;
                }
            }
            .icon{
                border: 2px solid #e67900;
                border-radius: 113px;
                width: 90px;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                img{
                    height:40px;
                }
            }
            span{
            color:#481e06;
            font-size:16px;   
            }
            .title{
                color:#23788c;
                font-weight: bold;
                font-size:22px;
                padding: 0 40px;
                text-align: center;
            }
        }
    }
}
.bloc-startup-questionnaire{
    width: 65%;
    min-height: 400px;
    border-radius: 15px;
    box-shadow: 0.9px 1.8px 10px 0 rgba(211, 208, 201, 0.3);
    border: solid 1px #d3d0c9;
    background-color: #f4f3f0;
    margin:0 auto 60px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding:30px;
    @media #{$mobile} { 
        width: 90%;
        padding:15px;
    }
    .title-first{
        color:#481e06;
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 60px;
        line-height: 1.3;
    }
    .number-question{
        color:#e67a02;
        font-size:15px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
    }
    .title{
        color:#481e06;
        font-size:22px;
        text-align: left;
        margin-bottom: 40px;
        font-weight: 600;
        line-height: 1.3;
        width: 100%;
        white-space: normal;
        word-break: break-word;
    }
        .btn-orange{
            &:hover{
                color:$orange;
            }
        }
    .option{
        width: 100%;
        label{
            font-size: 14px;
        }
        > .form-group{
            padding:0;
            .line__radio-form{
                justify-content: space-between;
                width: 100%;
                @media #{$mobile} { 
                    flex-flow: wrap;
                }
                .radio-button{
                    width: 45%;
                    @media #{$mobile} { 
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .action{
        display: flex;
        justify-content: center;
        border-top: 1px solid #d3d0c9;
        padding-top: 31px;
        width:100%;
        button{
            margin:0 10px;
            &.btn-orange{
                &:hover{
                    color:$orange;
                }
            }
        }
    }
}
.bloc-map{
    background-color: #f4f3f0;
    .description-top-content {
        padding-bottom:50px;
        .h3{
            text-align: center;
            width: 100%;
        }
        p{
            text-align: center;
            width: 100%;
        }
    }
}
#map {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px
  }
.map-list{
    display: flex;
    flex-flow: wrap;
    .col-md-7{
        padding-left: 0;
        padding-right: 0;
        @media #{$mobile} { 
            width:100%;
            min-height: 200px;
        }
    }
    .list-agence{
        padding-left: 200px;
        padding-right: 40px;
        padding-top: 30px;
        background-color: white;
        @media #{$tablet_landscape_max_width} { 
            padding-left:15px;
            padding-right:15px;
        }
        @media #{$tablet_portrait_max_width} { 
            padding-left:15px;
            padding-right:15px;
        }
        @media #{$mobile} { 
            padding-left:15px;
            padding-right:15px;
        }
        .content{
            h4{
                color:#23788c;
                text-align: center;
                font-weight: bold;
                font-size: 30px;
                width: 100%;
                margin-bottom: 20px;
            }
            .select-form{
                position: relative;
                margin-bottom:20px;
                border-radius: 30px;
                background-color: #f3f2f1;
                &:before{
                    content: "\ea50";
                    font-family: "icomoon";
                    position: absolute;
                    right: 18px;
                    display: inline-block;
                    font-size: 23px;
                    top: 36%;
                    transform: translateY(-50%) rotate(180deg);
                }
                select{
                    position: relative;
                    background-color: transparent;
                    background-image:none;
                    color:#9a9a99;
                    font-weight: 500;
                    border:0;
                    padding-left:20px;
                }
            }
        }
        .slick-list{
            height: 380px !important;
        }
        .list{
            .item{
                padding:10px;
                cursor: pointer;
                &.active-item{
                    .content{
                        background-color:#23788c;
                        .title{
                            color:white;
                        }
                        p{
                            color:white;
                        }
                        .link{
                            .rendez-vous{
                                border-color:white;
                                color:white;
                            }
                            .tel{
                                color:white;
                                &:before{
                                    border-color:white;
                                    color:white;
                                }
                            }
                        }
                    }
                }
                .content{
                    border-radius: 20px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                    background-color: #ffffff;
                    padding:20px;
                }
                .title{
                    width: 100%;
                    display: block;
                    color:#481e06;
                    font-weight: bold;
                    margin-bottom: 6px;
                    text-transform: uppercase;
                }
                p{
                    margin-bottom: 0;
                    color:#262626;
                    font-weight: 300;
                    line-height: 1.2;
                    font-size: 14px;
                }
                .link{
                    display: flex;
                    margin-top: 6px;
                    .rendez-vous{
                        border: 1px solid #e67e04;
                        border-radius: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        font-size: 15px;
                        color: #481e06;
                        &:hover{
                            background-color:#e67e04;
                            color:white;
                        }
                        &:before{
                            content: "\e914";
                            font-family: 'icomoon';
                            position: relative;
                            left: 2px;
                        }
                    }
                    .tel{
                        display: flex;
                        align-items: center;
                        color:#e67e04;
                        font-weight: 500;
                        padding-left: 4px;
                        padding-right: 10px;
                        border-radius: 100px;
                        margin-left: 5px;
                        &:hover{
                            background-color:#e67e04;
                            color:white;
                            &:before{
                                border-color:white;
                                color:white;
                                width: 25px;
                                height: 25px;
                            }
                        }
                        &:before{
                            content: "\e92c";
                            font-family: 'icomoon';
                            border: 1px solid #e67e04;
                            border-radius: 100px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            text-align: center;
                            font-size: 15px;
                            color: #481e06;
                            margin-right:10px;
                        }
                    }
                }
            }
        }
    }
}
.container-small{
    max-width: 900px;
    margin: 0 auto;
}
.text-orange{
    color:$orange;
}
.text-green{
    color:#45929f;
}
.financement{
    margin-top: 30px;
    .price{
        color:$orange;
        font-weight: 500;
        width: 100%;
        text-align: center;
        font-size: 22px;
    }
    .title{
        color:#481e06;
        font-weight: bold;
        text-align: center;
        position: relative;
        font-size: 26px;
        margin-bottom: 15px;
        &:after{
            content: "";
            background-color:#e67e04;
            width: 60px;
            height: 5px;
            display: block;
            margin: 19px auto 10px;
        }
    }
    ul{
        opacity: 1;
        margin-top: 22px;
        &:before{
            display: none;
        }
        li{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 18px;
            line-height: 1.3;
            font-size: 15px;
            text-align: left;
            &:before{
                content: "";
                background: url(#{$image}list-check.svg) no-repeat center center;
                min-width: 25px;
                height: 25px;
                display: block;
                margin-right: 8px;
            }
        }
    }
    .content{
        padding:30px 50px;
        border-radius: 10px;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        display: flex;
        flex-flow: column;
        height: 100%;
        span{
            text-transform: uppercase;
            font-weight: bold;
            font-size: 26px;
            margin-bottom:10px;
            display: block;
            width: 100%;
            text-align: center;
        }
        .text-center{
            min-height: 200px;
            display: flex;
            align-items: flex-start;
            flex-flow: column;
            justify-content: flex-start;
            p{
                max-width: 190px;
                line-height: 1.2;
            }
        }
    }
}
.decouvrir-statup{
    background: url(#{$image}bg-orange.png) no-repeat center center;
    width: 100%;
    height: 215px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding: 15px 55px;
    align-items: center;
    @media #{$mobile} {
        flex-flow: wrap;
        padding: 15px 15px;
    }
    h3{
        color: white;
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 0;
        @media #{$mobile} {
            width: 100%;
            font-size: 22px;
            text-align: center;
            line-height: 1.3;
        }
    }
    .btn-white{
        @media #{$mobile} {
            display: table;
            margin: 0 auto;
        }
    }
}
.autre-avantage{
    padding-top: 40px;
    padding-bottom: 40px;
    .h3{
        text-align: center;
        width: 100%;
        margin-bottom: 40px;
    }
    ul{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 15px;
        @media #{$mobile} {
            flex-flow: wrap;
            justify-content: center;
        }
        li{
            text-align: center;
            transition: 0.3s all;  
            @media #{$mobile} {
                width: 50%;
                margin-bottom: 30px;
            } 
            &:hover{
                transform: scale(1.09);
                a{
                    color:$orange;
                }
            }
            a{
                color:#333;
            }  
            img{
                height: 55px;
                margin-bottom: 15px;
            }
            span{
                width: 100%;
                display: block;
                font-weight: 500;
            }
        }
    }
}
.definition{
    p{
        line-height: 1.3;
        padding:0 50px;
        margin-bottom:20px;
        @media #{$mobile} {
            padding:0 0px;
        }
    }
}
.decouvrir-credit{
    background-color:#333;
    position: relative;
    .content{
        position: relative;
        height: 450px;
        .image{
            position: relative;
            height: 100%;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .text{
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            left:0;
            right:0;
            margin: 0 auto;
            max-width: 600px;
            color:white;
            text-align: center;
            h3{
                font-weight: 500;
                font-size: 30px;
                margin-bottom:30px
            }
            a{
                margin-top:30px
            }
        }
    }
}
.content-type{
    background-color:#f4f3f0;
    padding-top: 40px;
    padding-bottom: 40px;
    .content{
        background-color:white;
        padding: 40px;
        .bloc{
            margin-bottom: 30px;
            p{
                font-weight: 300;
                color:#262626;
            }
        }
        h4{
            color:#481e06;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 20px;
        }
    }

    .left-col{
        .content{
            max-height: 500px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 0.5em;
              }
               
              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              }
               
              &::-webkit-scrollbar-thumb {
                background-color: #b4b4b4;
                outline: 1px solid #b4b4b4;
              }
        }
        @media #{$mobile} { 
            order:1;
        }
    }
    .right-col{
        @media #{$mobile} { 
            order:0;
        }   
        .content{
            padding-left:0;
            padding-right:0;
            padding-top:0;
            padding-bottom: 0;
        }
        .menu{
            li{
                a{
                    color: #262626;
                    font-weight: 500;
                    padding: 16px 30px;
                    display: block;
                    width: 100%;
                    position:relative;
                    border-bottom: 3px solid #f4f3f0;
                    &:before{
                        content:"";
                    }
                    &:hover{
                        color:$orange;
                    }
                    &.active{
                        &:before{
                            content: "";
                            width: 4px;
                            background: #e67900;
                            height: 100%;
                            position: absolute;
                            LEFT: 0;
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}
.page_404{
    background-color:#f4f3f0;
    padding-top:80px;
    padding-bottom: 80px;
    .content-404{
        max-width: 830px;
        margin:0 auto;
        .content{
            border-radius: 15px;
            box-shadow: 0.9px 1.8px 10px 0 rgba(211, 208, 201, 0.3);
            border: solid 1px #d3d0c9;
            background-color: #ffffff;
            .image{
                background: url(#{$image}design-404.png) no-repeat center center;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 60px;
                background-size: 100% auto;
                background-position: 0px 81px;
                padding-bottom: 30px;
                position: relative;
                img{
                    max-width: 240px;
                }
                .icon-top-404{
                    background: url(#{$image}top-404.png) no-repeat center center;
                    position: absolute;
                    top: 16px;
                    display: block;
                    height: 60px;
                    width: 130px;
                    margin:0 auto;
                    left:0;
                    right:0;
                }
            }
            .text{
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                padding-bottom: 50px;
                padding-top: 20px;
                h3{
                    color:#481e06;
                    font-weight: bold;
                    font-size:32px;
                    margin-bottom: 30px;
                    padding-top: 30px;
                    &:after{
                        content: "";
                        background-color:$orange;
                        width: 60px;
                        height: 5px;
                        border-radius: 2.5px;
                        display: block;
                        margin: 15px auto 0;
                    }
                }
                h4{
                    color:#262626;
                    font-weight: 500;
                    font-size:28px;
                }
                p{
                    color:#262626;
                    font-weight: 100;
                    font-size: 26px;
                    line-height: 1.3;
                    text-align: center;
                }
                a{
                    margin-top:30px;
                    &:hover{
                        color:$orange;
                    }
                }
            }
        }
    }
}
.modal-video-youtube{
    .video-youtube{
        background-color: white;
        padding: 20px;
        position: relative;
        padding-top: 50px;
    }
    iframe{
        max-width: 600px;
        @media #{$mobile} { 
            max-width: 320px;
        }
    }
    .content{
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        &:before{
            content: "";
            background: rgba(0, 0, 0, 0.58);
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
        }
        .close{
            position: absolute;
            right: 21px;
            top: 13px;
            background: white;
            z-index: 2;
            opacity: 1;
            width: auto;
            height: auto;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            font-size: 26px;
            font-weight: 300;
            line-height: 36px;
            cursor: pointer;
        }
    }
    iframe{
        z-index: 1;
        position: relative;
    }
}
.mediatheque{
    .filtre{

    }
}
.listing-mediatheque{
    background-color: #f4f3f0;
    padding-top: 60px;
    padding-bottom: 60px;
    .item-media{
        padding: 0 30px;
        margin-bottom: 40px;
        &:hover{
            .image{
                img{
                    transform: scale(1.1);
                }
            }
        }
        &.type-video{
            &:hover{
                .image{
                    &:before{
                        display: none;
                    }
                }
                .txt{
                    margin-top: -75px;
                    position: relative;
                    padding-bottom: 50px;
                }
                .play{
                    display: block;
                }
            }
            .image{
                &:before{
                    content: "";
                    background-image: url(#{$image}play.svg);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
        &.type-brochure{
            h4{
                text-transform: none;
            }
            .btn-orange{
                padding: 8px 40px;
                &:hover{
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    content: "";
                    background-image: url(#{$image}consulter.svg);
                    display: block;
                    position: absolute;
                    left: 19px;
                    right: auto;
                    transform: none;
                    width: 24px;
                    height: 23px;
                    background-repeat: no-repeat;
                    top: 10px;
                    background-size: 14px;
                }
            }
            &:hover{
                .txt{
                    margin-top: -75px;
                    position: relative;
                    padding-bottom: 50px;
                }
                .taille{
                    display: block;
                }
                .play{
                    display: block;
                }
            }
            .image{
                background-color: #e67e04;
                padding-top: 40px;
                img{
                    max-width: 230px;
                    max-height: 223px;
                }
            }
        }
        .content{
            height: 365px;
            overflow: hidden;
        }

        .image,.video{
            position: relative;
            text-align: center;
            img{
                height: 245px;
                object-fit: cover;
                transition: transform 0.9s;
            }
        }
        .video{
            span{
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    height: 50px;
                }
            }
        }
        .play{
            display: none;
            margin: 0 auto;
            padding: 7px 19px;
            margin-top: 12px;
            &:hover{
                color:$orange;
            }
        }
        .taille{
            display: none;
            color:#481e06;
            font-size:12px;
            margin-top: 20px;
        }
        .txt{
            background-color: white;
            padding: 18px 20px;
            padding-right: 50px;
            box-shadow: 0.9px 1.8px 16px 0 rgba(211, 208, 201, 0.56);
            transition: 0.3s all;  
        }
        .date{
            color:#481e06;
            font-size: 11px;
        }
        h4{
            font-weight: 500;
            color:#262626;
            text-transform: uppercase;
            font-size: 20px;
            margin-top: 15px;
            line-height: 1.3;
            height: 50px;
        }
    }
}
.chargerplus{
    .btn-orange{
        &:hover{
            color:$orange !important;
        }
    }
}
.telechargement{
    .container{
        border-radius: 10px;
        border: solid 2px #dcdce1;
        padding-top: 60px;
        padding-bottom: 60px;
        @media #{$mobile} { 
            border: solid 0px #dcdce1;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }
    .list{
        margin: 0 auto;
        padding-top: 40px;
        @media #{$mobile} { 
            padding-right: 15px;
            padding-left: 15px;
        }
        .item{
            display: flex;
            align-items: center;
            background-color: #f4f3f0;
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 10px;
            position: relative;
            width: 700px;
            transition: 0.3s all; 
            @media #{$mobile} { 
                width: 100%;
            }
            &:hover{
                .download{
                    width: 56px;
                    img{
                        opacity: 1;
                    }
                }
            }
            .icon{
                margin-right: 12px;
            }
            label{
                margin-bottom: 0;
                color:#3c3c3c;
                font-weight: 500;
                @media #{$mobile} { 
                    width: 60%;
                    line-height: 23px;
                }
                span{
                    font-weight: 400;
                    color:#757575;
                    font-size: 14px;
                }
            }
            .download{
                position: absolute;
                background-color: $orange;
                color:white;
                border: 0;
                right: 0;
                height: 100%;
                padding: 0;
                width: 8px;
                transition: 0.3s all; 
                @media #{$mobile} { 
                    width: 60px;
                }
                img{
                    max-width: 19px;
                    opacity: 0;
                    @media #{$mobile} { 
                        opacity: 1;
                    }   
                }
            }
        }
    }
}
.paginate{
    ul{
        display: flex;
        justify-content: center;
        align-items: center;
        li{
            a,span{
                background-color: white;
                width: 38px;
                height: 38px;
                border-radius: 120px;
                color: #481e06;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 4px;
                border:2px solid white;
                position: relative;
                &:hover{
                    border-color:$orange;
                    color:$orange;
                }
                &.prev{
                    font-size: 0;
                    &:before{
                        content: "\ea50";
                        font-family: $icomoon;
                        color: #481e06;
                        font-size: 20px;
                        order: 1;
                        display: block;
                        position: absolute;
                        top: 48%;
                        transform: translateY(-50%) rotate(-90deg);
                        right: 4px;
                        top: 18px;
                        font-weight: bold;
                    }
                }
                &.next{
                    font-size: 0;
                    &:before{
                        content: "\ea50";
                        font-family: $icomoon;
                        color: #481e06;
                        font-size: 20px;
                        order: 1;
                        display: block;
                        position: absolute;
                        top: 48%;
                        transform: translateY(-50%) rotate(90deg);
                        left: 4px;
                        top: 18px;
                        font-weight: bold;
                    }
                }
            }
            &.current{
                a,span{
                    background-color: transparent;
                    border:2px solid $orange;
                    color:$orange;
                }
            }
        }
    }
}
.tabbedPanels{
    width: 100%;
    > ul{
        display: flex;
        justify-content: center;
        position: relative;
        &:before{
            content: "";
            background-color: #dcdce1;
            height: 2px;
            width: 100%;
            position: absolute;
            bottom: 33px;
            display: block;
            @media #{$mobile} {
                bottom: 49px;
            }
        }
        li{
            text-align: center;
            padding: 0 40px;
            @media #{$mobile} {
                padding: 0 15px;
            }
            &:nth-child(3){
                a{
                    color:$orange;
                    opacity:1;
                    span{
                        color:$orange;
                    }
                }
            }
            a{
                color:#333333;
                font-weight: 600;
                opacity: 0.2;
                display: block;
                &.active{
                    opacity: 1;
                    span{
                        &:before{
                            opacity: 1;
                        }
                    }
                }
            }
            img{
                height: 60px;
                @media #{$mobile} {
                    height: 30px;
                }
            }
            span{
                display: block;
                width: 100%;
                color:#333333;
                font-weight: 600;
                @media #{$mobile} {
                    font-size: 13px;
                }
                &:before{
                    content: "";
                    background-color: $orange;
                    width: 128px;
                    height: 5px;
                    display: block;
                    opacity: 0;
                    margin: 18px auto;
                    @media #{$mobile} { 
                        width: 45px;
                    }
                }
            }
        }
    }
    .panelContainer{
        padding: 30px 20px;
        .image{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 15px;
            img{
                margin: 0 10px;
                max-height: 100px;
            }
        }
        p{
            text-align: center;
            font-size: 20px;
        }
    }
}
.cookies{
    width: 380px;
    height: 337px;
    border-radius: 20px;
    box-shadow: 5px 0px 30px 0 rgba(38, 38, 38, 0.2);
    border: solid 5px #dcdce1;
    background-color: #ffffff;
    padding: 30px;
    position: fixed;
    bottom: 30px;
    z-index: 5;
    left: 25px;
    @media #{$mobile} { 
        display: none;
    }
    .top{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .title{
        color:#481e06;
        padding-left: 20px;
        font-size: 22px;
        font-weight: 500;
    }
    p{
        color:#545454;
        font-weight: 300;
        font-size: 15px;
    }
    .action{
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        .btn{
            padding: 9px 27px;
            &.btn-orange{
                &:hover{
                    color:$orange;
                }
            }
        }
    }
}