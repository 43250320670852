.list-category-faq{
	.form-group{
		position: relative;
		input[type="text"]{
			border-radius: 28px;
			padding-left: 22px;
		}
		button{
			background-color: transparent;
			font-size: 0;
			border:0;
			position: absolute;
			right:0;
			padding: 12px 21px;
			&:before{
				content: "\e914";
				font-family: $icomoon;
				font-size:20px;
				color:$black;
			}
		}
	}
	ul{
		border-left:1px solid #B4D4F1;
		padding-left: 21px;
		padding-top: 20px;
		margin-top: 20px;
		li{
			font-size: 16px;
			font-weight: 400;
			a{
				color:$black;
				padding: 6px 0;
				display: block;
				position: relative;
				&:before{
					content: "";
					background-color: $blue;
					height: 0%;
					position: absolute;
					width: 2px;
					left: -21px;
					top: 0;
					transition: height 0.2s ease-out;
				}
				&.active{
					color:$blue;
					&:before{
						height: 95%;
						transition: height 0.3s ease-in;
					}
				}
				&:hover{
					color:$blue;
					&:before{
						height: 95%;
						transition: height 0.3s ease-in;
					}
				}
			}
		}
	}
}
.list-faq{
	.question{
		border-bottom: 1px solid $gray--07;
		padding: 18px 20px;
		cursor: pointer;
		transition: 0.3s all;
		&.active{
			background-color: $blue--05;
			.icon-action{
				&:before{
					content:"";
					width: 1px;
					height: 25px;
					background-color: $blue;
					display: block;
					position: absolute;
					left: 11px;
					top: 0px;
					transform: rotate(-45deg);
				}
				&:after{
					content:"";
					width: 1px;
					height: 25px;
					background-color: $blue;
					display: block;
					position: absolute;
					left: 11px;
					top: 0;
					transform: rotate(45deg);
				}
			}
			p{
				overflow: visible;
				text-overflow: visible;
				display: block;			
			}
		}
		.icon-action{
			position: absolute;
			right: 25px;
			transform: rotate(180deg);
			font-size: 41px;		
			&:before{
				content: "\ea50";
				font-family: $icomoon;
				color:$gray--07;
			}
		}
		.title{
			@extend .border-design;
			&:before{
				background-color: $blue;
			}
		}
		h3{
			font-size: 25px;
			margin-bottom: 20px;
		}
		p{
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			display: -webkit-box;
			-webkit-box-orient: vertical;	
			transition: 0.3s all;	
		}
		.link{
			a{
				color:$blue;
				font-size: 13px;
			}
		}
	}
}
.mediatheque{
	.filtre{
		.list{
			display: flex;
			flex-flow: wrap;
			padding-bottom: 40px;
			max-width: 850px;
			margin: 0 auto;
			.radio-button{
				width: 33.3%;
				margin-bottom: 20px;
				cursor: pointer;
				@media #{$mobile} { 
					width: 100%;
				}
				.content{
					padding:0 10px;
				}
				input[type="radio"]{
					cursor: pointer;
					&:checked{
						&+{
							label{
								color: #fff;
								background-color:#e67900;
							}
						}
					}
				}
				label{
					border-radius: 10px;
					border: solid 2px #e67900;
					color: #e67900;
					text-align: center;
					display: flex;
					justify-content: center;
					padding: 20px 15px;
					margin-bottom: 0;
					font-weight: 500;
					cursor: pointer;
					&:before{
						display: none !important;

					}
					&:after{
						display: none !important;
					}
				}
			}
		}		
	}

}
.faq{
	.filtre{
		margin-bottom:40px;
		h3{
			margin-bottom: 30px;
		}
	}
	.list{
		display: flex;
		flex-flow: wrap;
		.radio-button{
			width: 33.3%;
			margin-bottom: 20px;
			cursor: pointer;
			@media #{$mobile} { 
				width: 100%;
			}
			.content{
				padding:0 10px;
			}
			input[type="radio"]{
				cursor: pointer;
				&:checked{
					&+{
						label{
							color: #fff;
							background-color:#e67900;
						}
					}
				}
			}
			label{
				border-radius: 10px;
				border: solid 2px #e67900;
				color: #e67900;
				text-align: center;
				display: flex;
				justify-content: center;
				padding: 20px 15px;
				margin-bottom: 0;
				font-weight: 500;
				cursor: pointer;
				&:before{
					display: none !important;

				}
				&:after{
					display: none !important;
				}
			}
		}
	}
}
.bloc-faq{
	background-color: #f4f3f0;
	padding-top:60px;
	padding-bottom:60px;
	.h4{
		text-align: center;
		color: #481e06;
		font-weight: normal;
		margin-bottom: 30px;
	}
	.container{
		max-width: 800px;
	}
	.card{
		background: transparent;
		border:0;
		margin-bottom:20px;
		.card-header{
			background-color:white;
			border:0;
			position: relative;
			&:before{
				content:"";
				display:inline-block;
				width: 35px;
				height: 35px;
				
				background:
				  linear-gradient(#fff,#fff),
				  linear-gradient(#fff,#fff),
				  #000;
				background-position:center;
				background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
				background-repeat:no-repeat;
				background:
				linear-gradient(#000,#000),
				linear-gradient(#000,#000);
			  background-position:center;
			  background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
			  background-repeat:no-repeat;
			  position: absolute;
			  right: 14px;
			  top: 5px;
			}
			&.active{
				&:before{
					height: 4px;
					top: 19px;
					background:
					linear-gradient(#fff,#fff),
					linear-gradient(#fff,#fff),
					$orange;
				  background-position:center;
				  background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
				  background-repeat:no-repeat;
				  background:
				  linear-gradient($orange,$orange),
				  linear-gradient($orange,$orange);
				background-position:center;
				background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
				background-repeat:no-repeat;
				}
			}
			h5{
				button{
					width: 100%;
					background-color: transparent;
					border:0;
					color: #481e06;
					font-weight: 500;
					text-align: left;
					font-size: 17px;
					position: relative;
					z-index: 2;
					&:focus{
						box-shadow: none;
						outline: none;
					}
				}
			}
		}
		.card-body{
			background-color:white;
			margin-top: 8px;
		}
	}
}
.besoin-page-faq{
	background: url(#{$image}bg-faq-footer.png) no-repeat;
	background-position: center center;
	background-size: 100% 100%;
	background-color: #f8f9fc;
	margin-top: 60px;
}