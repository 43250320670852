.banner-page{
    position: relative;
    &.no-banner{
        height: 320px;
        &:before{
            display: none;
        }
        h3{
            color:#481e06 !important;
        }
        p{
            color:#262626 !important;
        }
    }
    &:before{
        content: "";
        background: #481E07;
        background: -webkit-linear-gradient(right, #481E07, transparent);
        background: -moz-linear-gradient(right, #481E07, transparent);
        background: linear-gradient(to right, #481E07, transparent);
        position: absolute;
    display: block;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: 0;
    background-size: cover;
    left: 0;
    }
    .image{
        img{
            width: 100%;
            object-fit: cover;
            max-height: 490px;
            @media #{$mobile} { 
                height:400px;
            }
        }
    }
    .text{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        z-index: 1;
        max-width: 1200px;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-right: 600px;
        @media #{$tablet_landscape_max_width} { 
            padding-right: 15px;
        }
        &.text-center{
            padding-right: 15px;
            width: 100%;
            max-width: 800px;
        }
        h2{
            font-size: 44px;
            color: white;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 20px;
            line-height: 1.4;
            @media #{$mobile} { 
                font-size:30px;
                line-height: 1.2;
            }
        }
        h3{
            font-size: 38px;
            color: white;
            font-weight: 700;
            text-transform: none;
            margin-bottom: 20px;
            line-height: 1.4;
            @media #{$mobile} { 
                font-size:30px;
                line-height: 1.2;
            }
        }
        p{
            font-size:18px;
            color:white;
        }
        a{
            margin-top: 20px;
            &:after{
                display: none;
            }
        }
    }
}
.bg-marron{
    background-color:#f4f3f0;
}