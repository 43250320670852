html{
    height: 100%;
}
body{
    font-family: $fontBody;
    height: 100%;
    background-color: #fff;
}
    li{
        list-style: none;
    }

a{
    text-decoration: none;
    transition: 0.3s all;
    outline: none;
    cursor: pointer;    
    &:hover{
        color:$orange;
    }
}
.h3{
    font-size: 30px;
    color:#481e06;
	text-transform: none;
	line-height: 1.3;
	text-align: center;
	font-weight: 700;
	@media #{$mobile} { 
		font-size:23px;
		padding:0 15px;
	}	
}
.h2{
    font-size: 30px;
    color:#481e06;
	text-transform: none;
	line-height: 1.3;
	text-align: center;
	font-weight: 700;
	width: 100%;
	@media #{$mobile} { 
		font-size:23px;
		padding:0 15px;
	}
	&:after{
		content: "";
		width:60px;
		height:5px;
		background-color: $orange;
		display: block;
		margin: 15px auto 0;
		border-radius: 6px;
	
	}
}
.text-center{
    text-align:center;
}
img {
    max-width: 100%;
    height: auto;
}
.border-design{
    &:before{
        content: "";
        width: 30px;
        background: white;
        height: 4px;
        display: block;
        border-radius: 13px;
        position: relative;
        top: 0;
        margin-bottom: 25px;
        margin-left: 0px;
    }
}

.hidden{
    display: none;
}
.show{
    display: block;
}
.full-width{
    width:100% !important;
}
h1{
    text-transform: uppercase;
}
h3 {
    font-size: 19px;
    margin-bottom: 5px;
}
a{
    text-decoration: none;
    &:hover{
        text-decoration:none;
    }
}
p {
    margin-bottom: 10px;
    font-size:rem(16);
    line-height: 1.6;
    strong {
        font-weight: bold;
    }
}
.m-t-0{
	margin-top: 0 !important;
}
.m-b-30{
	margin-bottom:30px;
}
.m-t-100{
    margin-top:80px;
}
.p-t-50{
	padding-top: 50px;
	@media #{$mobile} { 
		padding-top: 20px;
	}
}
.p-t-70{
	padding-top: 70px;
	@media #{$mobile} { 
		padding-top: 20px;
	}
}
.p-b-70{
    padding-bottom: 70px;
}
.p-t-120{
	padding-top: 120px;
	@media #{$mobile} { 
		padding-top: 20px;
	}
}
.p-b-120{
    padding-bottom: 120px;
}
.facebook{
    &:before{
        content:"\e909";
        font-family: $icomoon;
    }
}
.twiter{
    &:before{
        content:"\e907";
        font-family: $icomoon;
    }
}
.instagram{
    &:before{
        content:"\e90a";
        font-family: $icomoon;
    }
}
.align-center{
    text-align: center;
}
.full-padding {
	padding-left: 50px !important;
	padding-right: 50px !important;
	@media #{$mobile} {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}
.small-text{
	font-size:13px;
	display: block;
	text-align: center;
	margin-top: 20px;
}
/** gestion error **/
.aucun-resultat{

}
.alert{
    .panel-heading{
        margin-bottom: 25px;
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 600;
        .panel-subtitle{
            display: block;
            width: 100%;
            font-size: 12px;
            margin-top: 5px;
        }
    }
}
.f-modal-alert {
	background-color: #fff;
	margin: 20px;
    padding: 50px;
    h1{
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 35px;
    }
    .btn-warning{
        margin-top: 50px;
    }
}

.f-modal-alert .f-modal-icon {
	border-radius: 50%;
	border: 4px solid gray;
	box-sizing: content-box;
	height: 80px;
	margin: 20px auto;
	padding: 0;
	position: relative;
	width: 80px;

	// Success icon
	&.f-modal-success,
	&.f-modal-error {
		border-color: #A5DC86;

		&:after,
		&:before {
			background: #fff;
			content: '';
			height: 120px;
			position: absolute;
			transform: rotate(45deg);
			width: 60px;
		}

		&:before {
			border-radius: 120px 0 0 120px;
			left: -33px;
			top: -7px;
			transform-origin: 60px 60px;
			transform: rotate(-45deg);
		}

		&:after {
			border-radius: 0 120px 120px 0;
			left: 30px;
			top: -11px;
			transform-origin: 0 60px;
			transform: rotate(-45deg);
		}

		.f-modal-placeholder {
			border-radius: 50%;
			border: 4px solid rgba(165, 220, 134, .2);
			box-sizing: content-box;
			height: 80px;
			left: -4px;
			position: absolute;
			top: -4px;
			width: 80px;
			z-index: 2;
		}

		.f-modal-fix {
			background-color: #fff;
			height: 90px;
			left: 28px;
			position: absolute;
			top: 8px;
			transform: rotate(-45deg);
			width: 5px;
			z-index: 1;
		}

		.f-modal-line {
			background-color: #A5DC86;
			border-radius: 2px;
			display: block;
			height: 5px;
			position: absolute;
			z-index: 2;

			&.f-modal-tip {
				left: 14px;
				top: 46px;
				transform: rotate(45deg);
				width: 25px;
			}

			&.f-modal-long {
				right: 8px;
				top: 38px;
				transform: rotate(-45deg);
				width: 47px;
			}
		}
	}

	// Error icon
	&.f-modal-error {
		border-color: #F27474;

		.f-modal-x-mark {
			display: block;
			position: relative;
			z-index: 2;
		}

		.f-modal-placeholder {
			border: 4px solid rgba(200, 0, 0, .2);
		}

		.f-modal-line {
			background-color: #F27474;
			top: 37px;
			width: 47px;

			&.f-modal-left {
				left: 17px;
				transform: rotate(45deg);
			}

			&.f-modal-right {
				right: 16px;
				transform: rotate(-45deg);
			}
		}
	}
	
	// Warning icon
	
	&.f-modal-warning {
		border-color: #F8BB86;
		
		&:before {
			animation: pulseWarning 2s linear infinite;
			background-color: #fff;
			border-radius: 50%;
			content: "";
			display: inline-block;
			height: 100%;
			opacity: 0;
			position: absolute;
            width: 100%;
            left:0;
		}
		
		&:after {
			background-color: #fff;
			border-radius: 50%;
			content: '';
			display: block;
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 1;
		}
	}

	&.f-modal-warning .f-modal-body {
		background-color: #F8BB86;
		border-radius: 2px;
		height: 47px;
		left: 50%;
		margin-left: -2px;
		position: absolute;
		top: 10px;
		width: 5px;
		z-index: 2;
	}

	&.f-modal-warning .f-modal-dot {
		background-color: #F8BB86;
		border-radius: 50%;
		bottom: 10px;
		height: 7px;
		left: 50%;
		margin-left: -3px;
		position: absolute;
		width: 7px;
		z-index: 2;
	}
	
	+ .f-modal-icon {
		margin-top: 50px;
	}
}

.animateSuccessTip {
	animation: animateSuccessTip .75s;
}

.animateSuccessLong {
	animation: animateSuccessLong .75s;
}

.f-modal-icon.f-modal-success.animate:after {
	animation: rotatePlaceholder 4.25s ease-in;
}

.f-modal-icon.f-modal-error.animate:after {
	animation: rotatePlaceholder 4.25s ease-in;
}

.animateErrorIcon {
	animation: animateErrorIcon .5s;
}

.animateXLeft {
	animation: animateXLeft .75s;
}

.animateXRight {
	animation: animateXRight .75s;
}

.scaleWarning {
    animation: scaleWarning 0.75s infinite alternate;
}

.pulseWarningIns {
    animation: pulseWarningIns 0.75s infinite alternate;
}

@keyframes animateSuccessTip {
	0%,54% {
		width: 0;
		left: 1px;
		top: 19px;
	}

	70% {
		width: 50px;
		left: -8px;
		top: 37px;
	}

	84% {
		width: 17px;
		left: 21px;
		top: 48px;
	}

	100% {
		width: 25px;
		left: 14px;
		top: 45px;
	}
}

@keyframes animateSuccessLong {
	0%,65% {
		width: 0;
		right: 46px;
		top: 54px;
	}

	84% {
		width: 55px;
		right: 0;
		top: 35px;
	}

	100% {
		width: 47px;
		right: 8px;
		top: 38px;
	}
}

@keyframes rotatePlaceholder {
	0%,5% {
		transform: rotate(-45deg);
	}

	100%,12% {
		transform: rotate(-405deg);
	}
}

@keyframes animateErrorIcon {
	0% {
		transform: rotateX(100deg);
		opacity: 0;
	}

	100% {
		transform: rotateX(0deg);
		opacity: 1;
	}
}

@keyframes animateXLeft {
	0%,
	65% {
		left: 82px;
		top: 95px;
		width: 0;
	}

	84% {
		left: 14px;
		top: 33px;
		width: 47px;
	}

	100% {
		left: 17px;
		top: 37px;
		width: 47px;
	}
}

@keyframes animateXRight {
	0%,
	65% {
		right: 82px;
		top: 95px;
		width: 0;
	}

	84% {
		right: 14px;
		top: 33px;
		width: 47px;
	}

	100% {
		right: 16px;
		top: 37px;
		width: 47px;
	}
}

@keyframes scaleWarning {
	0% {
		transform: scale(1);
	}
	
	30% {
		transform: scale(1.02);
	}
	
	100% {
		transform: scale(1);
	}
}

@keyframes pulseWarning {
	0% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	30% {
		background-color: #fff;
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		background-color: #F8BB86;
		transform: scale(2);
		opacity: 0;
	}
}

@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
}
.icon-simulation{
	&:before{
		content: "\e91a";
		font-family: 'icomoon';
	}
}
.icon-credit{
	&:before{
		content: "\e909";
		font-family: 'icomoon';
	}
}
.icon-agence{
	&:before{
		content: "\e90f";
		font-family: 'icomoon';
	}
}
.icon-rendezvous{
	&:before{
		content: "\e91d";
		font-family: 'icomoon';
	}
}
.sticky-rightbar{
	position: fixed;
    right: 20px;
    z-index: 2;
	top: 120px;
	@media #{$mobile} { 
		right: 0;
		top: auto;
		bottom: 0;
		width: 100%;
		transform: none !important;
		top: auto !important;
	}
	ul{
		display: flex;
		flex-flow: column;
		align-items: flex-end;
		@media #{$mobile} { 
			flex-flow: wrap;
		}
		li{
			margin-bottom: 8px;
			display: inline-block;
			width: 40px;
			@media #{$mobile} { 
				width: 25%;
				margin-bottom: 0;
				&:last-child{
					a{
						border-right: 0 !important;
					}
				}
			}
			a{
				display: flex;
				align-items: center;
				height: 40px;
				background-color: $orange;
				color:white;
				border-radius: 120px;
				overflow: hidden;
				box-shadow: -2px 0 7px 0 rgba(0, 0, 0, 0.25);
				@media #{$mobile} { 
					border-radius: 0;
					justify-content: center;
					box-shadow: none;
					height: 60px;
					margin: 0 0.3px;
				}
				span{
					width: 0;
					position: relative;
					transition: all 0.5s 0.3s ease;
					height: 50px;
					opacity: 0;
					right: 51px;
					background-color:$orange;
					color:white;
					border-radius: 100px;
					display: flex;
					align-items: center;
					padding-left: 51px;
					font-size: 12px;
					white-space: nowrap;
					@media #{$mobile} { 
						display: none;
					}
				}
				i{
					width: 45px;
					height: 40px;
					min-width: 45px;
					min-height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 21px;
					position: relative;
					z-index: 1;
					@media #{$mobile} { 
						font-size: 28px;
					}
				}

			}
			&:hover{
				width: 100%;
				@media #{$mobile} { 
					width: auto;
				}	
				span{
					width: 160px;
					transition: all 0.5s ease;
					opacity: 1;
					@media #{$mobile} { 
						opacity: 0;
						width: auto;
					}
				}
			}
		}
	}
}