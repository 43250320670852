////// NavBar ///////////
.col__menu--mobile{
	display: none;
	@media #{$tablet_landscape_max_width} { 
		display: block;
		position:absolute;
		right:15px;
		z-index: 999;
	}
	@media #{$mobile} { 
		right:0;
		height: 100%;
		background-color: $orange;
		width: 60px;
	}
}
body.is-fixed{
	&:before{
		content: "";
		background: rgba(0, 0, 0, 0.54);
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.col__menu--mobile{
		top: 0;
		.c-nav__icon{
			span{
				&:before{
					background-color:white !important;
				}
				&:after{
					background-color:white !important;
				}
			}

		}

	}
}
.header__bottom{
	background-color: #333;
	position: fixed;
    left: 0;
	width: 240px;
	height: 100%;
	top:0;
	box-shadow: 10px -9px 20px -14px rgba(0,0,0,0.75);
	&.showmenu{
		width: 90px;
		.header__logo{
			padding: 15px 0;
			img{
				display: none;
			}
			.logo-small{
				display: block !important;
				width: 80px;
				margin: 0 auto;
				height: 43px;
			}
		}
		.o-nav{
			ul{
				li{
					justify-content: center;
					padding: 21px 10px;
					&:before{
						margin-right: 0;
					}
					&:after{
						display: none;
					}
					a{
						font-size: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						svg{
							width: 40px;
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

