.c-paginate{
    ol{
        display: flex;
        justify-content: center;
        li{            
            background-color: white;
            margin-right: rem(8);
            border:1px solid #CFD9E3;
            border-radius: 8px;
            transition: .2s ease all;
            &:hover{
                background-color: white;
                border-color:$orange;
                a{
                    color:$orange;
                }
            }
            &.prev{
                border-color:$blue;
                .prev{
                    font-size: 0;
                    padding: 0;
                    width: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 36px;
                    &:before{
                        content:"\e906";
                        font-family: $icomoon;
                        font-size: 14px;
                        transform: rotate(180deg);
                    }
                }
            }
            &.next{
                border-color:$blue;
                .next{
                    font-size: 0;
                    padding: 0;
                    width: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 36px;
                    &:before{
                        content:"\e906";
                        font-family: $icomoon;
                        font-size: 14px;
                    }
                }
            }            
            a{
                color:$blue;
                padding:rem(10) rem(13);
                display: block;
                transition: .2s ease all;
            }
            &.current{
                border-color:$blue;
                color:white;
                padding:rem(10) rem(13);
                background-color: $blue;
            }
        }
    }
}