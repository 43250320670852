// ========================================= //
// placeholder
// ========================================= //

// Change placeholder color
// Internet Explorer 10+ (!)
@mixin placeholder-color($color, $font-size: 14px) {
  &::-webkit-input-placeholder { /* WebKit browsers */
    color:$color;
    transition:0.3s ease;
    opacity: 1;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:$color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:$color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:$color;
  }
}