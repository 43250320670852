.nos-atouts {
	.title{
		@extend .border-design;
		&:before{
			background-color: $orange;
		}
	}
	.list{
		margin-top:40px;
		.bloc-atout{
			display: flex;
			flex-flow: wrap;
			.text{
				padding: 40px 30px;
				h3{
					margin-bottom: 30px;
					font-weight: 500;
					line-height: 28px;
					position: relative;
					padding-left: 80px;
					@media #{$mobile} { 
						padding-left: 50px;
					}
					span{
						font-size: 53px;
						margin-right: 11px;
						color: #e2e2e2;
						position: absolute;
						left: 0;
						font-weight: 900;
						z-index: -1;
						@media #{$mobile} { 
							font-size: 35px;
						}
					}
				}
			}
			.image{
				padding: 0;
				position: relative;
				img{
					height:320px;
					width: 100%;
					object-fit: cover;
				}
			}
			&:nth-child(2n){
				.image{
					order:1;
					@media #{$mobile} { 
						order:0;
					}
				}
				.text{
					order:0;
					@media #{$mobile} { 
						order:1;
					}
				}
			}
		}
	}
}