.section--6{
	overflow: hidden;
	.title{
		margin-bottom: 70px;
		@extend .border-design;
		&:before{
			background: $orange;
		}
	}
	.content-agence{
		.row{
			margin-left: 0;
			margin-right: 0;
		}
	}
	.liste-ville{
		padding-left: 0;
		padding-right: 0;
		@media #{$tablet_portrait} {
			flex: 0 0 100%;
			max-width: 100%;
		}
		@media #{$mobile} {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	.maps{
		position: relative;
		padding-left: 0;
		padding-right: 0;
		@media #{$tablet_portrait} {
			flex: 0 0 100%;
			max-width: 100%;
		}
		@media #{$mobile} {
			flex: 0 0 100%;
			max-width: 100%;
		}
		img{
			height: 100%;
			object-fit: cover;
		}
		.text{
			position: absolute;
			top:50%;
			transform: translateY(-50%);
			color:white;
			text-align: center;
			width: 100%;
			img{
				margin-right: 13px;
			}
			a{
				color:white;
				text-align: center;
				span{
					color:#9ECAF2;
				}
			}
		}
		&:before{
			content: "";
			background-color: rgba(3, 86, 160, 0.76);
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 0;
		}
	}
	.card{
		border: 1px solid #0356A0;
		box-shadow: none;
		border-radius: 0;
		border-left: 0;
		border-right: 0;
		margin-top: 0px;
		padding-left: 100px;
		border-top:0;
		&:first-child{
			border: 1px solid #0356A0;
		}
		@media #{$mobile} {
			padding-left: 15px;
		}	
		.card-header{
			background-color: white;
			border-top: 0;
			border-bottom: 0;
			border-left: 0;
			border-radius: 0;
			h5{
				button{
					width: 100%;
					border:0;
					font-size: 18px;
					text-transform: uppercase;
					text-align: left;
					color:$black;
					text-decoration: none;
					padding-left: 15px;
					padding-top: 5px;
					padding-bottom: 5px;
					&:before{
						display: none;
					}
					&:hover{
						background-color: transparent;
					}
				}
			}
		}
		div{
			.card-body{
				display: flex;
				padding-right: 130px;
				@media #{$mobile} { 
					padding-right: 15px;
					flex-flow: wrap;
					> div{
						max-width: 100%;
						width: 100%;
						margin-bottom: 20px;
					}
				}	
				.name{
					font-size: 16px;
					color:$black;
					display: block;
					width: 100%;
					margin-bottom: 5px;
				}
				.metier{
					font-size:14px;
					color:$gray;
				}
				ul{
					li{
						display: flex;
						margin-bottom: 20px;
						label{
							font-size: 16px;
							color:$black;
							min-width: 100px;
							max-width: 100px;
						}
						span{
							font-size: 15px;
							color:$black;
							line-height: 21px;
						}
					}
				}
			}
		}
	}
}