.content-publication{
	.liste{
		ul{
			li{
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				box-shadow: 3px 1px 11px 1px rgba(222,222,222,1);
				justify-content: space-between;
				transition: .2s ease all;
				@media #{$tablet_portrait_max_width} { 
					flex-flow: wrap;
				}	
				&:hover{
					background-color: $gray--06;
					.caracteristique{
						.action{
							.btn-download{
								color:$orange;
								border-color: $orange;
								&:hover{
									color:white;
								}
							}
						}
					}
				}
				.name{
					width: 250px;
					font-size: 20px;
					color:$black;
					background-color: $gray--06;
					padding: 13px 17px;
					display: flex;
					min-height: 70px;
					align-items: center;
					@media #{$tablet_portrait_max_width} { 
						width: 100%;
					}
				}
				.caracteristique{
					display: flex;
					align-items: center;
					font-size: 14px;
					justify-content: space-between;
					@media #{$tablet_portrait_max_width} { 
						flex-flow: wrap;
						padding-top: 20px;
						width: 100%;
					}
					.date{
						padding: 0px 15px;
   						width: 155px;
						&:before{
							content: "\e910";
							font-family: $icomoon;
							color:$orange;
							margin-right: 6px;
						}
					}
					.taille{
						padding: 0 15px;
						border-left:1px solid #707070;
						border-right:1px solid #707070;
						width: 155px;
						@media #{$tablet_portrait_max_width} { 
							border-right: 0;
						}
						&:before{
							content: "\e912";
							font-family: $icomoon;
							color:$orange;
							margin-right: 6px;
						}
					}
					.action{
						padding-left: 40px;
						padding-right: 25px;
						@media #{$tablet_portrait_max_width} { 
							padding-left: 0;
							padding-right: 0;
							width: 100%;
							display: flex;
							justify-content: center;
							margin: 15px 0;
						}
					}
					.btn-download{
						&:after{
							content: "\e911";
							font-family: $icomoon;
							font-size: 19px;
						}
					}
				}
			}
		}
	}
	.liste{
		margin-bottom: 60px;
	}
	.filtre-date{
		max-width: 650px;
		margin: 0 auto 60px;
	}
	.slick-list{
		.slick-slide{
			width: 60px;
		}
	}
	.slick-arrow{
		position: absolute;
		background-color: transparent;
		border:0;
		font-size: 0;
		top:0;
		&:before{
			content: "";
			font-family: $icomoon;
		}
		&.slick-prev{
			transform: rotate(180deg);
			left: -60px;
			&:before{
				content: "\e901";
				font-size: 14px;
			}

		}
		&.slick-next{
			right:-20px;
			&:before{
				content: "\e901";
				font-size: 14px;
			}

		}
	}
}