// ========================================= //
// fonts
// ========================================= //

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 3, 2018 */
@import url('https://fonts.googleapis.com/css?family=Damion|Muli:400,600,900,800');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed|Barlow:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,400,500,600,700,800,900&display=swap');

$opensans: 'Fira Sans', sans-serif;
$Lato: 'Lato', sans-serif;
$icomoon: 'icomoon';
$fontBody: 'Fira Sans';


@font-face {
    font-family: 'icomoon';
    src: url('../../../medias/fonts/icomoon.eot');
    src: url('../../../medias/fonts/icomoon.eot?#iefix') format('embedded-opentype'), url('../../../medias/fonts/icomoon.ttf') format('truetype'), url('../../../medias/fonts/icomoon.woff?#iefix') format('woff'), url('../../../medias/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Klavika-Regular';
    src: url('../../../medias/fonts/Klavika-Regular.eot');
    src: url('../../../medias/fonts/Klavika-Regular.eot?#iefix') format('embedded-opentype'), url('../../../medias/fonts/Klavika-Regular.ttf') format('truetype'), url('../../../medias/fonts/Klavika-Regular.woff?#iefix') format('woff'), url('../../../medias/fonts/Klavika-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Klavika-Medium';
    src: url('../../../medias/fonts/KlavikaMedium-OSF.eot');
    src: url('../../../medias/fonts/KlavikaMedium-OSF.eot?#iefix') format('embedded-opentype'), url('../../../medias/fonts/KlavikaMedium-OSF.ttf') format('truetype'), url('../../../medias/fonts/KlavikaMedium-OSF.woff?#iefix') format('woff'), url('../../../medias/fonts/KlavikaMedium-OSF.svg') format('svg');
    font-weight: normal;
    font-style: normal;
} 
