// ========================================= //
// navOffCanvas
// ========================================= //


.o-nav {
    .menu-socialmedialinks,
    .menu__bottom-mobile,
    .c-langue-mobile,
    .c-langue{
        display: none;
    }    
     @media #{$tablet_landscape_max_width} { 
        position: fixed;
        background-color: $orange;
        top: 0;
        padding-top: rem(18);
        right: 0;
        z-index: 999;
        width: rem($offCanvas--width);
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        -ms-overflow-style: none;
        &::-webkit-scrollbar { width: 0 !important }
        @media #{$mobile} { 
            width: 70%;
        }
        .menu-socialmedialinks,
        .c-langue-mobile{
            display: block;
        }    
        .menu__bottom-mobile{    
            display:flex;
        }
        .is-active__menu{
            &.c-nav__level0 {
                > li{
                    a{
                        color:$gray;
                        &:after{
                            background-color:$gray;
                        }
                    }
                }
            }
        }
        .c-nav__level0 {
            padding-left: 0;
            height: auto;
            flex-flow: column;
            &:before { display: none; }

            > li {
                position: relative;
                display: block;
                width: 100%;
                float: none;
                padding-bottom: rem(10);    
                a {
                    padding-left: rem(10);
                    padding-right: rem(10);
                    height: auto;
                    color:white;
                    text-align: left;
                    display: block;
                    padding-bottom: rem(10);
                    &:hover {

                    }
                }
            }
        }

        .c-nav__level1 {
            position: fixed;
            z-index: 110;
            top: 0;
            display: block;
            float: none;
            width: rem(280);
            left: inherit;
            height: 100%;
            transform: inherit;
            background: white;
            padding-top: 0;

            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
            -ms-overflow-style: none;
            &::-webkit-scrollbar { width: 0 !important }

            > li {
                position: relative;

                a {
                    height: rem($offcanvas--nav--item--height);;
                    font-size: rem(18);
                }
            }
        }
    }

    .c-nav__show {
        position: absolute;
        display: none;
        top: 0;
        right: 0;
        width: rem($offcanvas--nav--item--height);
        height: rem($offcanvas--nav--item--height);
        text-align: center;
        color: $white;
        font-size: rem(20);
        cursor: pointer;
        z-index: 60;
        @media #{$tablet_landscape_max_width} { 
            display: flex;
        }   
        &:hover {

        }
    }

    li.c-nav__hide {
        position: relative;
        display: none !important;
        width: 100%;
        padding: 12px 15px;
        height: auto;
        color: $blue;
        text-align: left;
        cursor: pointer;

         @media #{$tablet_landscape_max_width} { 
            display: block !important;
        }
        .c-iae__icon--arrowLeft{
            &:before{
                content:"\e902";
                font-family: $icomoon;
                color:$orange;
            }
        }
        .c-text {
            font-size: rem(22);
            height: 100%;
           padding-left: rem(15);
        }

        &:hover {
        }          
    }    
}

.o-nav {
    transition: 0.3s all;

    @media #{$tablet_landscape_max_width} { 
        right: 0;
        transform: translateX(100%);
        transform:translate(100%,0px);
    }

    &.is-inViewport {
        @media #{$tablet_landscape_max_width} { 
            transform: translateX(0);
            transform:translate(0,0px);
            padding-bottom: rem(90);
        }
    }
}
.o-nav .c-nav__level1 {
    transition: 0.3s all;

    @media #{$tablet_landscape_max_width} { 
        right: 0;
        transform: translateX(100%);
        transform:translate(100%,-32px);
    }

    &.is-inViewport {
        @media #{$tablet_landscape_max_width} { 
            transform: translateX(0);
            transform:translate(61px,-32px);
            padding-top: rem(25);  
            border-top:0;  
            height: 100%;
            max-height: 100% !important;
            z-index: 9999;    
        }
    }
}
.c-nav__icon {
    $sideMenu: rem(28);
    position: relative;
    display: none;
    width: rem($offcanvas--nav--item--height);
    height: rem($offcanvas--nav--item--height);

    @media #{$tablet_landscape_max_width} { 
        display: block;
    }
    @media #{$mobile} { 
        height:100%;
        margin:0 auto;
    }
    span {
        $strokeHeight: 3px;

        display: block;
        text-indent: -9999px;
        width: 26px;
        height: $strokeHeight;
        position: relative;
        top: 4px;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        transition: 0.3s all;

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
            width: 20px;
            height: $strokeHeight;
            transition: 0.3s all;
            border-radius: rem(2);
            right: 0;
        }

        &:before {
            top: rem(-8);
            transform: rotate(0deg);
        }

        &:after {
            bottom: rem(-8);
            transform: rotate(0deg);
        }
    }

    span, span:before, span:after {
        background: $orange;
        @media #{$mobile} { 
            background-color: white;
        }
    }

    &.is-active {
        position: relative;
        top: 0;
        right: 0;
        z-index: 120;

        span:before, span:after {
            background: $blue;
        }
        
        span {
            background-color: transparent;

        &:before {
                top: 0;
                transform: rotate(45deg);
                width: 26px;
            }

            &:after {
                bottom: 0;
                transform: rotate(-45deg);  
                width: 26px;
            }
        }
    }
}

.c-nav__close {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;

    &.is-active {
        display: block;
    }
}
