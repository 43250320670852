// ========================================= //
// colors
// ========================================= //


$white: #F0F2F5 !default;
$success: #2ecc71 !default;
$error: #e74c3c !default;

$green:#00944f;

$blue: #0356A0 !default;
$orange: #e67900 !default;
$black: #393939 !default;

$black2:#434141 !default;
$blue--02: #2E73B0 !default;
$blue--03: #A6CBEC !default;
$blue--04: #103B56 !default;
$blue--05: #F3F9FE !default;
$midnight: #002140 !default;
$orange2: #8f221a !default;
$gray: #878787 !default;
$yellow: #ffce32 !default;

$gray--02: #707070 !default;

$gray--03: #F1F3F6 !default;

$gray--04: #F3F3F3 !default;

$gray--05: #E4E4E4 !default;
$gray--06: #ECECEC !default;
$gray--07: #BCBBBB !default;
$gray--08: #6b6a69 !default;



$midnight--02: #1a3853 !default;

$midnight--03: #183651 !default;


$borderColor: $gray--02;


$border: 1px solid $borderColor;


$facebookColor: #3B5998 !default;
$twitterColor: #00ACED !default;
$instagramColor: #BC2A8D !default;
$youtubeColor: #BB0000 !default;

