.chiffre{
	display: flex;
	justify-content: space-between;
	margin-top: 80px;
	width: 100%;
	@media #{$tablet_portrait} { 
		flex-flow: wrap;
		justify-content: center;
	}
	@media #{$mobile} { 
		flex-flow: wrap;
		padding: 0 15px;
	}
	.chiffre-content{
		display: flex;
		align-items: center;
		@media #{$tablet_portrait} { 
			width: 50%;
			margin-bottom: 30px;
			justify-content: center;
		}
		@media #{$mobile} { 
			width: 100%;
			margin-bottom: 30px;
		}
		.text{
			margin-left: 25px;
			span{
				font-size: 50px;
				@media #{$tablet_portrait} { 
					font-size: 40px;
				}
				@media #{$mobile} { 
					font-size: 35px;
				}	
			}
			p{
				font-size: 18px;
				color:$gray;
				margin-bottom: 0;
			}
		}
	}
}