header{
    display: flex;
    align-items: center;
    flex-flow: wrap;;
    &.fixed{
       /* width:100%;
        position:fixed;
        top:0;
        left:0;
        background:#FFF;
        transition:all 0.7s ease 0s;
        z-index:100;
        box-shadow:0px 1px 5px #999;
        padding: 0;
        @media #{$mobile} { 
            padding-bottom: 35px;
        }
        progress {
            top:82px;
            display: block;
            @media #{$mobile} { 
                display: none;
            }
        }
        .header-top{
            display: none;
        }
        .header__blocright{
            flex-flow:wrap;
            .header__top-bloc{
                order: 1;
                align-items: center;
                padding-left: 15px;     
                .demande-simulation{
                    display: none;
                }          
            }
            .header__bottom-bloc{
                .menu{
                    ul{
                        li{
                            a{
                                padding: rem(6) rem(8) rem(60);
                                font-size: 14px;
                            }
                        }
                    }
                }
                .espace-client{
                    margin-right: 10px;
                    margin-left: 8px;
                    @media #{$mobile} { 
                        margin: 0;
                    }
                }
            }
        }
        .logo{
            width: 200px;
            max-width: 200px;
            transition: 0.3s all;
            img{
                display: none;
                &.img-sticky{
                    display: block;
                }
            }
        }
        */
    }
    @media #{$mobile} { 
        position: relative;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .header-bottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.3);
        @media #{$mobile} { 
            flex-flow: wrap;
        }
    }
    .header-top{
        width: 100%;
        border-bottom: 1px solid rgba(206, 207, 209, 0.54);
        padding: 20px 30px;
        @media #{$mobile} { 
            padding: 11px 15px;
        }
        .header__top-bloc{
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;
            .menu_left{
                @media #{$mobile} { 
                    display: none;
                }
            }
            ul{
                display: flex;
                li{
                    margin: 0 15px;
                    a,span{
                        font-size: 13px;
                        color:$gray--08;
                        text-transform: uppercase;
                        &:hover{
                            color:$orange;
                        }
                    }
                }
            }
            .menu_right{
                ul{
                    li{
                        margin: 0 8px;
                        &:before{
                            content: "";
                            font-family: $icomoon;
                            margin-right: 10px;
                            position: relative;
                            top: 2px;
                        }
                        &:nth-child(1){
                            &:before{
                                content: "\e921";
                            }
                            
                        }
                        &:nth-child(2){
                            &:before{
                                content: "\e920";
                            }
                            
                        }
                    }
                }
            }
        }
    }
    .logo{
        max-width: 200px;
        width:200px;
        transition: 0.3s all;
        padding: 18px 0;
        @media screen and (max-width: 1300px) {
            max-width: 145px;
        }
        @media #{$tablet_landscape_max_width} { 
            padding: 15px 0;
            max-width: 150px;
        }
        .img-sticky{
            display: none;
        }
        @media #{$mobile} { 
            img{
                max-width: 120px;
               
            }
        }
    }
    .right-header{
        display: flex;
        align-items: center;
        margin-left: 20px;        
        .search{
            margin-right: 12px;
            padding-right: 10px;  
            position: relative; 
            cursor: pointer;
            &::after{
                content: "";
                width: 1px;
                height: 12px;
                background-color: black;
                position: absolute;
                right: -3px;
                top: 3px;
            }         
            &:before{
                content: "\e907";
                font-family: $icomoon;
            }
        }
        .lang{
            padding-left: 5px;
            position: relative;
            &:after{
                content: "\e906";
                font-family: "icomoon";
                font-size: 11px;
                transform: rotate(90deg);
                display: inline-block;
                margin-left: 7px;
            }
            select{
                border:0;
                font-size: 17px;
                font-weight: 300;
                color:black;
            }
            span{
                font-size: 17px;
                font-weight: 300;
                color:black;
                &:after{
                    content: "\e906";
                    font-family: "icomoon";
                    font-size: 11px;
                    transform: rotate(90deg);
                    display: inline-block;
                    margin-left: 7px;
                }
            }
            ul{
                display: none;
            }
        }
    }
    .header__blocleft{
        display: flex;
        align-items: center; 
        padding-left: 25px;
    padding-right: 30px;      
    @media only screen and (max-width: 1320px){
        padding-left: 15px;
        padding-right: 15px;
    }
        .menu{
            transition: 0.3s all;
            padding-left: 50px;
            @media only screen and (max-width: 1450px){
                padding-left: 20px;
            }
            &.is-inViewport{
                @media #{$tablet_landscape_max_width} { 
                padding-top: 40px;
                    ul{
                        flex-flow: wrap;
                        li{
                            width: 100%;
                            margin-bottom: 30px;
                            a{
                                color:white;
                            }
                        }
                    }
                }
            }
            ul{
                display: flex;
                li{
                    position: relative;
                    &:first-child{
                        a{
                            font-size:0;
                            &:before{
                                content: "\e90e";
                                font-family: $icomoon;
                                font-size: 21px;
                                position: relative;
                                top: -3Px;
                            }
                        }
                    }
                    &:hover{
                        &:after{
                            width: 80%;
                        }
                    }
                    &.active{
                        color:$orange;
                        border:1px solid $orange;
                        border-radius: 26px;
                        a{
                            color:$orange;
                        }
                    }
                    &:after{
                        content: "";
                        width: 0;
                        background: $orange;
                        height: 1px;
                        position: absolute;
                        bottom: -7px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transition: 0.3s all;
                    }
                    a{
                        font-size: rem(14);
                        color:#838281;
                        font-weight: 500;
                        text-transform: uppercase;
                        padding: rem(0) rem(12) rem(0);
                        position: relative;
                        transition: 0.3s all;
                        z-index: 9;
                        @media only screen and (max-width: 1450px){
                            font-size: rem(13);
                        }
                        @media only screen and (max-width: 1270px){
                            font-size:12px;
                        }
                        @media #{$tablet_landscape_max_width} { 
                            padding-bottom: 6px;
                        }
                        &:hover{
                            color:$orange;
                        }
                    }
                    ul{
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.3s ease-in-out;
                        position: absolute;
                        min-width: 250px;
                        background: white;
                        z-index: 1;
                        padding: 25px 30px;
                        border-radius: 15px;
                        top: 66px;
                        left: -48px;
                        display: flex;
                        flex-flow: column;
                        &:after{
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 0 10px 10px 10px;
                            border-color: transparent transparent #ffffff transparent;
                            position: absolute;
                            top: -9px;
                            left: 0;
                            right: 0;
                            margin: 0 auto;                          
                        }
                        li{
                            margin-bottom: 15px;
                            &:after{
                                display: none;
                            }
                            a{
                                font-size: 14px;
                                font-weight: normal;
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                padding-bottom: 6px;
                                &:after{
                                    display: none;
                                }
                                &:before{
                                    content: "\e901";
                                    font-family: "icomoon";
                                    position: relative;
                                    left: -19px;
                                    transition: all 0.5s ease;
                                    color:$blue;
                                    font-size: 7px;
                                    margin-left: -25px;
                                }
                            }
                            &:hover{
                                a{
                                    color:$blue;
                                    padding-left: 0;
                                    &:before{
                                        left:0;
                                        margin-right: 10px;
                                        margin-left: 0px;
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        ul{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .header__blocright{
        display: flex;
        flex-flow: column;
        .header__top-bloc{
            display: flex;
            justify-content: flex-end;
            padding: rem(13) 0;
            @media #{$mobile} { 
                position: absolute;
                top: 0;
                width: 100%;
                left: 0;
                padding: 15px 15px;                
            }
            .demande-simulation{
                a{
                    background-color: white;
                    border:1px solid $orange;
                    color:$orange;
                    padding: rem(8) rem(20);
                    font-size: rem(13);
                    border-radius: 40px; 
                }
            }
        }
        .header__bottom-bloc{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            transition: 0.3s all;
            height:100%;
            @media #{$tablet_landscape_max_width} { 
                padding-right: 70px;
            }
            @media #{$mobile} { 
                padding-right: 60px;
                position: absolute;
                right: 0;
                top: 0;
            }
            .searh{
                font-size:0;
                height:100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0 31px;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                @media only screen and (max-width: 1450px){
                    padding: 0 20px;
                }
                @media only screen and (max-width: 1270px){
                    padding: 0 20px;
                }
                @media #{$tablet_landscape_max_width} { 
                    padding:0 30px;
                }
                @media #{$tablet_landscape_max_width} { 
                    padding:0 20px;
                }
                &:before{   
                    content:"\e916";
                    font-size:26px;
                    font-family: $icomoon;
                }
            }
            .lang{
                position: relative;
                padding: 0 15px;
                @media only screen and (max-width: 1450px){
                    padding: 0 5px;
                }
                @media only screen and (max-width: 1270px){
                    padding: 0 15px;
                }
                @media #{$tablet_landscape_max_width} { 
                    padding: 0 15px;
                }
                @media #{$mobile} { 
                    padding:0 15px;
                }
                cursor: pointer;
                height:100%;
                display: flex;
                align-items: center;
                &:before{
                    content: "\ea50";
                    font-family: $icomoon;
                    color:$orange;
                    order: 1;
                    display: block;
                    position: absolute;
                    top: 48%;
                    transform: translateY(-50%) rotate(180deg);
                    right: 18px;
                    @media only screen and (max-width: 1450px){
                        right: 10px;
                    }
                }
                select{
                    border:0;
                    text-transform: uppercase;
                    padding-right: 24px;
                    position: relative;
                    background: transparent;
                    cursor: pointer;
                }
                .arrow-bottom{
                    position: absolute;
                    right: 6px;
                    transform: rotate(180deg);
                    top: 30px;
                    @media #{$tablet_landscape_max_width} { 
                        top:22px;
                    }
                    @media #{$mobile} { 
                        top: 23px;
                        right: 3px;
                    }
                    &:before{
                        content: "\ea50";
                        font-family: $icomoon;
                        color:$orange;
                    }
                }
                span{
                }
                ul{
                    display: none;
                }
            }
            .espace-fournisseur{
                margin-right: 0px;
                margin-left: 8px;
                @media #{$mobile} { 
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 50%;
                    margin:0;
                }
                a{
                    background-color: $blue;
                    color: white;
                    text-transform: uppercase;
                    padding: 14px 27px 14px 48px;
                    font-size: 16px;
                    border-radius: 0;
                    position: relative;
                    font-weight: 500;
                    position: relative;
                    @media #{$mobile} { 
                        width: 100%;
                        display: block;
                        font-size: 13px;
                        border-radius: 0;
                        padding-left: 37px;
                        padding-right: 5px;
                    }
                    &:hover{
                        background-color: $orange;
                    }
                    &:after{
                        content: "\e91f";
                        font-family: "icomoon";
                        position: absolute;
                        right: auto;
                        left:20px;
                        font-size: 17px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }                
            }
            .espace-client{
                height:100%;
                @media #{$mobile} { 
                    display: none;
                }
                a{
                    background-color: $orange;
                    color: white;
                    padding: 14px 27px 14px 48px;
                    font-size: 18px;
                    border-radius: 0;
                    position: relative;
                    font-weight: normal;
                    position: relative;
                    height:100%;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background-color: #481e06;
                    }
                    @media #{$mobile} { 
                        width: 100%;
                        display: block;
                        font-size:13px;
                        border-radius: 0;
                    }
                    &:after{
                        content: "\e904";
                        font-family: "icomoon";
                        position: absolute;
                        right: auto;
                        left:20px;
                        font-size: 19px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            .sabonne{
                @media #{$mobile} { 
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 50%;
                    margin:0;
                }
                a{
                    background-color: white;
                    border:1px solid $blue;
                    color:$blue;
                    text-transform: uppercase;
                    padding: rem(8) rem(20);
                    font-size: rem(13);
                    border-radius: 40px;  
                    @media #{$mobile} { 
                        width: 100%;
                        display: block;
                        border-radius: 0;
                        background-color: $orange;
                        color:white;
                        border:0;
                        padding: rem(12) rem(20);
                    }
                    &:hover{
                        background-color: $blue;
                        color:white;
                    }                  
                }
            }
        }
    }
}
.search__form{
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    background:RGBA(230,121,0,0.9);
    left: 0;
    right: 0px;
    z-index: 9999;
   opacity: 0;
   visibility: hidden;
    padding-right: 32px;
    transition: 0.8s all; 
    .result{
        background: #fff;
        margin-top: 20px;
        padding: 30px;
        border-radius:10px;
        ul{
            li{
                margin-bottom: 15px;
                position: relative;
                padding-left: 20px;
                &:before{
                    content: "\e925";
                    font-family: 'icomoon';
                    position: absolute;
                    left: 0;
                    font-size: 11px;
                    top: 5px;
                    color: #d96400;
                }
                a{
                    color: #d96400;
                    font-weight: 600;
                    font-size: 13px;
                    text-decoration: underline;
                }
            }
        }
    }
    &.active{
        width: 100%;
        height: 100%;
        opacity: 1;
        visibility: visible;
    }
    .close{
        position: absolute;
        right: 25px;
        cursor: pointer;
        width: 24px;
        top: 20px;
        opacity: 1;
        &:before{
            content: "";
            width: 3px;
            height: 25px;
            background-color: white;
            display: block;
            position: absolute;
            left: 11px;
            top: 0px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }   
        &:after{
            content: "";
            width: 3px;
            height: 25px;
            background-color: white;
            display: block;
            position: absolute;
            left: 11px;
            top: 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }    
    }
    label{
        color:white;
        width: 100%;
        margin-bottom: 0;
        margin-right: 20px;
        max-width: 180px;
    }
    .container{
        padding-top: 100px;
    }
    form{
        width: 100%;
    }
    .form-group{
        display: flex;
        position: relative;
        margin-bottom: 0;   
        align-items: center;   
        input[type="text"]{
            background: transparent;
            border: 0;
            border-radius: 0;
            font-weight: 300;
            color: white;
            font-size: 23px;
            font-style: italic;
            border-bottom: 2px solid #fff;
            height: 68px;
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: white;
                opacity: 1; /* Firefox */
              }
              
              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: white;
              }
              
              &::-ms-input-placeholder { /* Microsoft Edge */
                color: white;
              }          
        }
        button{
            position: absolute;
            right: 0;
            background: white;
            border: 0;
            padding: 0;  
            font-size: 0;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 100px;
            bottom: 20px;
            &:before{
                content: "\e916";
                font-size: 20px;
                font-family: $icomoon;
                color:$orange;
            }          
        }  
    }
}