
.logo__home{
	max-width: 125px;
    margin: 0 auto 50px;
}
strong{
	font-weight: bold;
}
.input-effect{
	position: relative;
	width: 100%;
	margin-bottom: 40px;
	padding: 0;
	&:last-child{
		&:before{
			content: "\e905";
			font-family: $icomoon;
			position: absolute;
			right: 0;	
		}	
	}
	&:before{
		content: "\e906";
		font-family: $icomoon;
		position: absolute;
		right: 0;	
		font-size: 24px;	
		color: #737373;
	}
	input{
		border: 0;
		padding: 4px 0;
		border-bottom: 1px solid #737373;
		background-color: transparent;
		&~label{
			position: absolute;
			left: 0;
			width: 100%;
			top: 9px;
			color: #737373;
			transition: 0.3s;
			z-index: -1;
			letter-spacing: 0.5px;
			font-size: 14px;
			padding: 0 0px;
		}
		&:focus{
			outline: none;
			&~.focus-border{
				width: 100%;
				transition: 0.4s;				
			}
			&~label{
				top: -16px;
				font-size: 12px;
				color: $orange;
				transition: 0.3s;				
			}
		}
		&~.focus-border{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 2px;
			background-color: $orange;
			transition: 0.4s;			
		}
	}
}